import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { AbholerPage } from '../pages/abholer/abholer.page';
import { AbholerRollePage } from '../pages/abholer-rolle/abholer-rolle.page';
import { AbwesenheitPage } from '../pages/abwesenheit/abwesenheit.page';
import { AbwesenheitArtPage } from '../pages/abwesenheit-art/abwesenheit-art.page';
//import { Autosize2 } from '../directives/autosize/autosize2';
import { AbwesenheitKrankheitPage } from '../pages/abwesenheit-krankheit/abwesenheit-krankheit.page';
import { TranslateModule } from '@ngx-translate/core';

import {AutosizeModule} from 'ngx-autosize';
import { SpecialInfoPage } from '../pages/special-info/special-info.page';


const PAGES_COMPONENTS = [

  //AbholerPage,
  AbholerRollePage, 
  AbwesenheitPage,
  AbwesenheitArtPage,
  AbwesenheitKrankheitPage,
  AbholerPage,
  SpecialInfoPage
];

@NgModule({
    imports: [
        TranslateModule.forChild(),
        CommonModule,
        FormsModule,
        IonicModule,
        AutosizeModule,
        // IonicModule.forRoot(),
    ],
    declarations: [
        AbholerRollePage,
        AbwesenheitPage,
        AbwesenheitArtPage,
        AbwesenheitKrankheitPage,
        AbholerPage,
        SpecialInfoPage,
        //Autosize2,
    ]
})
export class ComponentsModule { }
