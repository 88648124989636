import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { KindyService } from '../../services/kindy.service';
import { Router } from '@angular/router';




@Component({
  selector: 'app-aktivitaeten',
  templateUrl: './aktivitaeten.page.html',
  styleUrls: ['./aktivitaeten.page.scss'],
})
export class AktivitaetenPage implements OnInit {

  act_list;
  act_list_read;
  loaderTimer;
  uplLoader;

  constructor(private modalController: ModalController,
    public kindyService: KindyService,
    private router: Router,
    private loadingCtrl: LoadingController) { }

  ngOnInit() {
    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    }

    this.act_list = this.kindyService.getUnreadActivities() //this.kindyService._activities
    this.act_list_read = this.kindyService.getReadActivities()
  }

  dismissModal() {
    this.modalController.dismiss()
  }

  async showLoader() {
    this.uplLoader = await this.loadingCtrl.create({
      message: ''
    });
    this.uplLoader.present()
  }

  openEntry(entry) {

    if (entry.entry_type == "news") {
      let detail_entry = this.kindyService.findNewsByUid(entry.entry_uid)
      //detail_entry = null
      if (detail_entry) {
        this.router.navigate(['neuigkeiten-detail/' + detail_entry.uid])
        this.dismissModal()
      }
      else {
        this.loadEntry(entry.entry_type, entry.entry_uid)
      }
    }
    else if (entry.entry_type == "events") {
      this.kindyService.prepareEventList()
      let detail_entry = this.kindyService.findEventByUid(entry.entry_uid)
      detail_entry = null
      if (detail_entry) {
        this.router.navigate(['termine-detail/' + detail_entry.uid])
        this.dismissModal()
      }
      else {
        this.loadEntry(entry.entry_type, entry.entry_uid)
      }
    }
    else if (entry.entry_type == "message") {
      let detail_entry = this.kindyService.findMessageByUid(entry.entry_uid)
      //detail_entry = null
      if (detail_entry) {
        this.router.navigate(['nachrichten-detail/' + detail_entry.uid])
        this.dismissModal()
      }
      else {
        this.loadEntry(entry.entry_type, entry.entry_uid)
      }
    }
    else if (entry.entry_type == "kitalists") {
      this.router.navigate(['abfragelisten']);
      this.dismissModal()
    }

    if (!entry["read"]) {
      this.readEntry(entry)
    }
  }

  readEntry(entry) {
    let ref = this
    entry.read = true
    this.kindyService.apiCall("activity_read", {
      "auth_token": this.kindyService.usrAutToken(),
      "child_uid": this.kindyService._currentChild,
      "usrId": this.kindyService._usrId,
      "entry_uid": entry.entry_uid
    })
      .subscribe(data => {
      },
        (err) => console.info(err)
      );

    setTimeout(async function () {
      ref.act_list = ref.kindyService.getUnreadActivities() //this.kindyService._activities
      ref.act_list_read = ref.kindyService.getReadActivities()
      await ref.kindyService.countUnreadActivities()
    }, 500);

  }

  async loadEntry(entry_type, entry_uid) {
    await this.showLoader()
    let ref = this
    this.loaderTimer = setTimeout(function () { ref.uplLoader.dismiss(); }, 30000);
    this.kindyService.apiCall("activity_detail", { "auth_token": this.kindyService.usrAutToken(), "usrId": this.kindyService._usrId, "entry_type": entry_type, "entry_uid": entry_uid })
      .subscribe(data => {
        ref.uplLoader.dismiss()
        clearTimeout(ref.loaderTimer);
        if (data["obj"]["entry_type"] == "news") {
          if (data["obj"]["entry"]) {
            this.router.navigate(['neuigkeiten-detail/' + data["obj"]["entry"].uid])
            this.dismissModal()
          }
        }
        else if (data["obj"]["entry_type"] == "message") {
          if (data["obj"]["entry"]) {
            ref.kindyService.messages_activites[entry_uid] = data["obj"]["entry"]
            this.router.navigate(['nachrichten-detail/' + data["obj"]["entry"].uid])
            this.dismissModal()
          }
        }
        else if (data["obj"]["entry_type"] == "events") {
          if (data["obj"]["entry"]) {
            ref.kindyService.events_activites[entry_uid] = data["obj"]["entry"]
            this.kindyService.prepareEventList()
            let detail_entry = this.kindyService.findEventByUid(entry_uid)
            this.router.navigate(['termine-detail/' + detail_entry.uid])
            this.dismissModal()
          }
        }
      },
        (err) => {
          console.info(err)
          this.uplLoader.dismiss()
        }
      );


  }

}
