import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Platform, ModalController, NavController } from '@ionic/angular';

import { KindyService } from './services/kindy.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Events } from './services/events';
import { StatusBar, Style } from '@capacitor/status-bar';
import { register } from 'swiper/element/bundle';
import { Badge } from '@capawesome/capacitor-badge';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { HTTP } from '@ionic-native/http/ngx';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private events: Events,
    private modalController: ModalController,
    private navCtlr: NavController,
    private kindyService: KindyService,
    private route: ActivatedRoute,
    private httpNative: HTTP,
    private translate: TranslateService,
  ) {

    this.initializeApp();

  }

  async initializeApp() {

    //let lang = this.translate.getBrowserLang();

    //if (lang != "de") {
    //  lang = "en";
    //}

    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({ style: Style.Light })
    }

    const usrlang = await this.kindyService.getUserLang()

    this.translate.setDefaultLang(usrlang);
    this.translate.use(usrlang)

    //console.log("__", this.translate.getBrowserLang());

    this.platform.ready().then(async () => {

      if (Capacitor.isNativePlatform()) {

        /* SSL PINNIN */
        this.httpNative.setServerTrustMode('pinned')
          .then(() => {
            console.log('[SUCCESS] SSL Pinning Starts!');
          })
          .catch(() => {
            console.log('[ERROR] SSL Pinning Fails!');
          });

      }

      //await Badge.set(0)
      //await Badge.increase();

      //this.socketService.setup()

      /*
      this.socketService
      .getMessage()
      .subscribe(msg => {
        console.log('Incoming msg', msg);
        ref.events.publish('socketio', { type: "xyz", data: msg });
      });
      */

      /*
      const socket = new Pusher("2d7c022dd330bb27526c");
      const channel = socket.subscribe('kindy_moments');
      */
      const ref = this

      /*
      channel.bind('kraken_upload', function (data) {
        ref.events.publish('pusher', { type: "kraken_upload", data: data });
        //ref.events.publish('pusher', {type: "kraken_upload", data: data });
      });
      */

      /*
      this.events.subscribe('broadcast', (obj) => {
        if (obj.type == "logged_in") {
          //this.nav.setRoot('TabsPage');

        }
        console.log('#broadcast: ', obj);
      });
      */

      if (Capacitor.isNativePlatform()) {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register();
          } else {
            // Show some error
          }
        });

        PushNotifications.addListener('registration', (token: Token) => {
          this.kindyService.fbaseToken(token.value)
        });

        PushNotifications.addListener('registrationError', (error: any) => {
          //alert('Error on registration: ' + JSON.stringify(error));
        });

        PushNotifications.addListener(
          'pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            //alert('Push received: ' + JSON.stringify(notification));
          },
        );

        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (notification: ActionPerformed) => {

            if (notification.actionId == 'tap') {

              if (notification["notification"]) {
                const data_push = notification["notification"]["data"]
                this.kindyService.setNotiParams(data_push.cat, data_push.entry_uid, data_push.child_uid, data_push.location_uid)

                this.events.publish('push:notification', { notification: data_push });

                /*
                setTimeout(function () {
                  ref.kindyService.resetToStart()
                }, 100);
                */
              }

            }

          },
        );
      }

      this.events.subscribe('push:notification', (data: any) => {

        /*
        setTimeout(function () {
          ref.kindyService.resetToStart()
        }, 100);
        */

        if (data.notification &&
          data.notification.child_uid &&
          data.notification.location_uid &&
          !(data.notification.child_uid == this.kindyService._currentChild && data.notification.location_uid == this.kindyService._currentLocation)) {
          this.kindyService.setCurrentChild(data.notification.child_uid)
          this.kindyService.setCurrentLocation(data.notification.location_uid)
          setTimeout(function () {
            ref.kindyService.resetToStart()
          }, 100);
        }
        else {
          ref.kindyService.resetToStart()
        }


      });

      App.addListener('appStateChange', ({ isActive }) => {

        this.kindyService.updateAppStatus(isActive)

        if (isActive) {

          let diff = Math.floor(Date.now() / 1000) - this.kindyService.setup_stamp

          if (diff > 30) {
            if (diff > 1800) { // halbe Stunde (1800)
              this.events.publish('push:notification', {});
            }
            else {
              this.kindyService.checkForUpdates()
            }
          }

        }
        else {



        }

      });



      /*
      this.platform.pause.subscribe(() => {
        console.log('[INFO] App paused ??');
      });

      this.platform.resume.subscribe(() => {
        let diff = Math.floor(Date.now() / 1000) - this.kindyService.setup_stamp

        //alert("resume: "+diff)

        if (diff > 30) {
          if (diff > 1800) { // halbe Stunde (1800)
            this.events.publish('push:notification', {});
          }
          else {
            this.kindyService.checkForUpdates()
          }
        }
        else {
          // this.kindyService.checkForUpdates()
        }
        //console.log('[INFO] App resumed ??', Math.floor(Date.now()/1000)-this.kindyService.setup_stamp );
      });
      */

    });



  }

}
