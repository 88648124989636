import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, ToastController } from '@ionic/angular';
import { KindyService } from '../../services/kindy.service';
import * as moment from 'moment';
import { AnhaengePage } from '../anhaenge/anhaenge.page';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mitteilung',
  templateUrl: './mitteilung.page.html',
  styleUrls: ['./mitteilung.page.scss'],
})
export class MitteilungPage implements OnInit {

  isModal:Boolean; 
  prop;
  childName;
  groupName;
  childFirstname;
  myphoto;
  uplLoader;
  loaderTimer;
  myDate;
  myAttachments = [];
  myMessage:String = "";
  deleteBtn = false;
  //showEdit = false;

  countShortmessages = 0 

  constructor( private modalController : ModalController,
               public kindyService : KindyService,
               private loadingCtrl : LoadingController,
               public toastController: ToastController,
               private router : Router,
               private translate: TranslateService,
               private alertCtrl : AlertController ) { }

  ngOnInit( ) {
    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    } 

    this.childFirstname = this.kindyService.childFromList(this.kindyService._currentChild+'__'+this.kindyService._currentLocation).firstname
    this.childName = this.kindyService.childFromList(this.kindyService._currentChild+'__'+this.kindyService._currentLocation).firstname+" "+this.kindyService.childFromList(this.kindyService._currentChild+'__'+this.kindyService._currentLocation).lastname
    this.groupName = this.kindyService.childFromList(this.kindyService._currentChild+'__'+this.kindyService._currentLocation).groupname
    this.myphoto = this.kindyService.childFromList(this.kindyService._currentChild+'__'+this.kindyService._currentLocation).pic
  
    if (this.kindyService.shortmessage_list) {
      this.countShortmessages = this.kindyService.shortmessage_list.length
    }
  }

  dismissModal() { 
    this.modalController.dismiss()
  } 

  async showLoader() {
    this.uplLoader = await this.loadingCtrl.create({
      message: ''
    });
    this.uplLoader.present()
  }

  async anhangModal() {
    
    this.kindyService.attachment_editable = true
    this.kindyService.attachment_list = JSON.parse(JSON.stringify(this.myAttachments))

    if (this.isModal) {
      const modal = await this.modalController.create({
        component: AnhaengePage,
        animated: false,
        componentProps: { 
          isModal: true,
        }
      });  
      modal.onDidDismiss().then(  (data) => {
        this.myAttachments =  JSON.parse(JSON.stringify(this.kindyService.attachment_list))
      }); 
      return await modal.present(); 
    }
    else {
      this.router.navigate(['anhaenge'])
    }    
  } 


  async sendForm() {
    let form_data:any = {}
    form_data.message = this.myMessage //this.ipMessage["value"]   
    form_data.attachments = this.myAttachments

    this.translate.get(['kurzmitteilung.label4', 'kurzmitteilung.label5', 'kurzmitteilung.label7', 'kurzmitteilung.label6']).subscribe(async (res) => {

      if (!form_data.message || form_data.message.length < 3) {
        this.kindyService.informAboutError(res["kurzmitteilung.label4"], res["kurzmitteilung.label5"])
      }
      else {
        await this.showLoader()
        let ref = this
        ref.loaderTimer = setTimeout( function() { ref.uplLoader.dismiss(); }, 30000);
        this.kindyService.apiCall("update_statusmessage", { "auth_token" : this.kindyService.usrAutToken(), "usrId" : this.kindyService._usrId, "child_uid" : this.kindyService._currentChild, "form_data" : form_data, "uid" : null })
        .subscribe(data => { 
          this.kindyService.shortmessage_list = (<any>data).obj.shortmessage_list
          ref.uplLoader.dismiss()
          clearTimeout(ref.loaderTimer);
          this.modalController.dismiss()

            this.kindyService.informAboutError( res["kurzmitteilung.label6"], res["kurzmitteilung.label7"])
          
        },
        err => {
          ref.uplLoader.dismiss()
          clearTimeout(ref.loaderTimer);
          this.kindyService.informAboutError("","")
        });
      }

    })
  }


  ngOnDestroy() {
    this.kindyService.attachment_list = null
  }

  ionViewWillEnter() {    

    if (this.kindyService.attachment_list) {
      this.myAttachments = JSON.parse(JSON.stringify(this.kindyService.attachment_list))
      this.kindyService.attachment_list = null
    }
  
  }

}
