import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, NavController } from '@ionic/angular';
import { KindyService } from '../../services/kindy.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kind-wechseln',
  templateUrl: './kind-wechseln.page.html',
  styleUrls: ['./kind-wechseln.page.scss'],
})
export class KindWechselnPage implements OnInit {

  uplLoader; 

  constructor( private modalController : ModalController,
               public kindyService : KindyService,
               private navCtrl : NavController,
               private router : Router,
               private loadingCtrl : LoadingController ) { }

  ngOnInit() {
    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    }
  }

  dismissModal() { 
    this.modalController.dismiss()
  }

  async showLoader() {
    this.uplLoader = await this.loadingCtrl.create({
      message: ''
    });
    this.uplLoader.present()
  }

  async switchChild(entry) {
    
    if (!(entry.child_uid==this.kindyService._currentChild && entry.location_uid==this.kindyService._currentLocation)) {
      await this.kindyService.setCurrentChild(entry.child_uid)
      await this.kindyService.setCurrentLocation(entry.location_uid)
      //await this.showLoader()

      this.dismissModal()

      this.navCtrl.setDirection('root');
      this.router.navigate(['/start'])

      //setTimeout(function(){ window.location.reload(); }, 100); 
      //this.navCtrl.setRoot("StartPage")
    } else {
      this.dismissModal()
    }

  }

}
