import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, LoadingController, AlertController } from '@ionic/angular';
import { KindyService } from '../../services/kindy.service';
import { AbholerRollePage } from '../abholer-rolle/abholer-rolle.page';
import { Router } from '@angular/router';
import { AbholerMitgliedPage } from '../abholer-mitglied/abholer-mitglied.page';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-abholer',
  templateUrl: './abholer.page.html',
  styleUrls: ['./abholer.page.scss'],
})
export class AbholerPage implements OnInit {

  isModal:Boolean; 
  myRole:String;
  myDateFrom:any = new Date().toISOString();
  myDateFromStr;
  myDateTime:any = new Date().toISOString();
  isLoading:Boolean = false;
  deleteBtn:Boolean = false; 
  myMessage:Text;
  myName:Text;
  uplLoader;
  loaderTimer;
  prop;
  noFixCollector=true; 
  showEdit:Boolean=false;
  showTime:Boolean=false;
   showDate = {
    date: false,
    time: false,
  }
  customDayShortNames = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

  constructor( private modalController : ModalController,
               private kindyService : KindyService,
               private navCtrl : NavController,
               private loadingCtrl : LoadingController,
               private translate: TranslateService,
               private alertCtrl : AlertController,
               private router : Router ) { }

  ngOnInit() {

    //if (!this.kindyService.collect_data) {
    //  this.kindyService.resetToStart()
    //  return   
    //}

    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    }

    this.translate.get(['abholer.day1', 'abholer.day2', 'abholer.day3', 'abholer.day4', 'abholer.day5', 'abholer.day6', 'abholer.day7']).subscribe((res) => {
      this.customDayShortNames = [res["abholer.day1"], res["abholer.day2"], res["abholer.day3"], res["abholer.day4"], res["abholer.day5"], res["abholer.day6"], res["abholer.day7"]];
    })

    if (this.kindyService.location_detail["settings_collector_type"] && this.kindyService.location_detail["settings_collector_type"] != "free") {
      this.noFixCollector = false
    }
    
    //console.log("--- this.kindyService.collect_data", this.kindyService.collect_data, "settings_collector_type",  this.kindyService.location_detail["settings_collector_type"])

    if (this.kindyService.collect_data.uid) {
      this.myDateFrom = this.kindyService.collect_data.date_from //new Date(this.kindyService.collect_data.date_from);
      this.deleteBtn = true
      this.myRole = this.kindyService.collect_data.role

      this.updateName()
      this.myDateTime = this.kindyService.collect_data.iso_time

      if (this.kindyService.collect_data.name && this.kindyService.collect_data.name.length > 2) {
        this.myName = this.kindyService.collect_data.name
      }
      
      this.myMessage = this.kindyService.collect_data.message
      //this.myDateFromStr = this.convertDateToStr(this.myDateFrom) 
    }
    else {
      this.myDateFrom = new Date().toISOString();
      //this.myDateFrom = new Date()
      //this.myDateFromStr = this.convertDateToStr(this.myDateFrom) 
    }
  
  }


  ionViewDidEnter() {
    this.updateName()
  }

  timeChanged() {
    console.log("timeChanged")
  }

  dateChanged() {

  }

  hideDateTime() {
    this.showDate.date = false
    this.showDate.time = false
  }

  changeDate() {
    this.showDate.date = !this.showDate.date
    this.showDate.time = false

  }

  changeTime() { 
    this.showDate.time = !this.showDate.time
    this.showDate.date = false
  }

  convertDateToStr(date) {
    let date_from = new Date(date)
    let mm:any
    let dd:any
    if (date_from.getMonth()+1 < 10) {
      mm = "0"+(date_from.getMonth()+1)
    } else {
      mm = date_from.getMonth()+1
    }
    if (date_from.getDate()+1 < 10) {
      dd = "0"+(date_from.getDate())
    } else {
      dd = date_from.getDate()
    }

    return dd+'.'+mm+'.'+date_from.getFullYear();
  }

  dismissModal() { 
    this.modalController.dismiss()
  }

  goBack() {
    this.navCtrl.back()
  }

  updateName() {
    if (this.kindyService.pickup_member && this.kindyService.pickup_member.length > 0 &&  this.kindyService.collect_data.member_uid) {
      for (let k=0 ; k<this.kindyService.pickup_member.length ; k++) {
        if (this.kindyService.pickup_member[k].uid ==this.kindyService.collect_data.member_uid ) {
          this.myName = this.kindyService.pickup_member[k].name
          this.myRole = this.kindyService.pickup_member[k].role
          break
        }
      }
    }
  }

  async memberModal() { 

    if (this.isModal) {
      const modal = await this.modalController.create({
        component: AbholerMitgliedPage,
        animated: false,
        componentProps: { 
          isModal: true,
        }
      }); 
      modal.onDidDismiss().then( (data) => {
        //this.myRole = this.kindyService.collect_data.role

        this.updateName()
      }); 

      return await modal.present(); 
    }
    else {
      this.router.navigate(['abholer-mitglied'])
    }
  }

  async rolleModal() {
    if (this.isModal) {
      const modal = await this.modalController.create({
        component: AbholerRollePage,
        animated: false,
        componentProps: { 
          isModal: true,
        }
      }); 
      modal.onDidDismiss().then( (data) => {
        this.myRole = this.kindyService.collect_data.role
      }); 

      return await modal.present(); 
    }
    else {
      this.router.navigate(['abholer-rolle'])
    }
  }

  async showLoader() {
    this.uplLoader = await this.loadingCtrl.create({
      message: ''
    });
    this.uplLoader.present()
  }


  async sendForm() {

    let form_data:any = {}

    let date_from = new Date(this.myDateFrom);
    form_data.date_from = date_from.getDate()+'.'+ (date_from.getMonth()+1) + '.'+date_from.getFullYear();

    let date_time = new Date(this.myDateTime);
    form_data.date_time = date_time.getHours()+":"+date_time.getMinutes()

    form_data.iso_time = this.myDateTime
    form_data.iso_from = this.myDateFrom
    form_data.role = this.myRole
    form_data.message = this.myMessage //this.ipMessage["value"]   
    form_data.name = this.myName
    form_data.member_uid = this.kindyService.collect_data.member_uid 

    if (!form_data.name || form_data.name.length < 3) {
      this.translate.get(['abholer.label1', 'abholer.error1']).subscribe((res) => {
        this.kindyService.informAboutError(res["abholer.label1"], res["abholer.error1"])
      })
    }
    else if (!form_data.iso_time) {
      this.translate.get(['abholer.label3', 'abholer.error2']).subscribe((res) => {
        this.kindyService.informAboutError(res["abholer.label3"], res["abholer.error2"])
      })
    }
    else if (this.noFixCollector && !form_data.role) {
      this.translate.get(['abholer.field1', 'abholer.label10']).subscribe((res) => {
        this.kindyService.informAboutError(res["abholer.label10"], res["abholer.field1"])
      })
    }
    else {

      await this.showLoader()
      
      let ref = this
      this.loaderTimer = setTimeout( function() { ref.uplLoader.dismiss(); }, 30000);
      this.kindyService.apiCall("create_collect_entry", { "v": 2, "auth_token" : this.kindyService.usrAutToken(), "child_uid" : this.kindyService._currentChild, "usrId" : this.kindyService._usrId, "form_data" : form_data, "uid" : this.kindyService.collect_data.uid })
      .subscribe(data => {
        this.kindyService.collect_data = (<any>data).obj.collect
        this.uplLoader.dismiss()
        clearTimeout(this.loaderTimer);
        this.translate.get(['abholer.info2']).subscribe((res) => {
          this.kindyService.informAboutError("", res["abholer.info2"])
        })
        if (this.isModal) {
          this.dismissModal()
        }
        else {
          this.goBack()
        }   
      },
      err => {
        this.uplLoader.dismiss()
        clearTimeout(this.loaderTimer);
        this.kindyService.informAboutError("","")
      });

    }

    //this.isLoading = true
  }

  ionViewWillEnter() {    
    if (this.kindyService.collect_data) {
      this.myRole = this.kindyService.collect_data.role
    }
  }

  async deleteForm() {
    let ref = this;

    this.translate.get(['abholer.header', 'abholer.message1', 'cancel', 'delete']).subscribe(async (res) => {
      let alert = await this.alertCtrl.create({
        header: res["abholer.header"],
        message: res["abholer.message1"],
        buttons: [
          {
            text: res["cancel"],
            role: 'cancel',
            handler: () => {
            }
          },
          {
            text:  res["delete"],
            handler: () => {
              this.showLoader()
              ref.loaderTimer = setTimeout( function() { ref.uplLoader.dismiss(); }, 30000);
              this.kindyService.apiCall("delete_collect_entry", { "auth_token" : this.kindyService.usrAutToken(), "usrId" : this.kindyService._usrId, "child_uid" : this.kindyService._currentChild, "uid" : this.kindyService.collect_data.uid })
              .subscribe(data => { 
                this.kindyService.collect_data = {} 
                ref.uplLoader.dismiss()
                clearTimeout(ref.loaderTimer);
                if (this.isModal) {
                  this.dismissModal()
                }
                else {
                  this.goBack()
                }  
              },
              err => {
                ref.uplLoader.dismiss()
                clearTimeout(ref.loaderTimer);
                this.kindyService.informAboutError("","")
              });
            }
          }
        ]
      });
      alert.present();
    })
  }


}
