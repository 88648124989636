import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { KindyService } from '../../services/kindy.service';

@Component({
  selector: 'app-abholer-mitglied',
  templateUrl: './abholer-mitglied.page.html',
  styleUrls: ['./abholer-mitglied.page.scss'],
})
export class AbholerMitgliedPage implements OnInit {

  role:string;
  prop;
  isModal:Boolean; 
  member_uid:string; 

  constructor( private modalController : ModalController,
               public kindyService : KindyService,
               private navCtrl : NavController ) { }

  ngOnInit() {
    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    }

    if (this.kindyService.collect_data) {
      this.member_uid = this.kindyService.collect_data.member_uid
    }
  } 

  chooseMember(entry) {
    this.kindyService.collect_data.member_uid = entry.uid
    this.goBack()
    
  }

  randyNr(idx) {
    var list = ["green", "pink", "blue", "yellow"];
    return list[idx - Math.floor(idx / 4) * 4];
  }

  goBack() { 
    if (this.isModal) {
      this.modalController.dismiss()
    }
    else {
      this.navCtrl.back()
    }
  }

}
