import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { KindyService } from '../../services/kindy.service';

@Component({
  selector: 'app-special-info',
  templateUrl: './special-info.page.html',
  styleUrls: ['./special-info.page.scss'],
})
export class SpecialInfoPage implements OnInit {

  constructor( private modalController : ModalController,
               public kindyService : KindyService ) { }

  ngOnInit() {

    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    }
    
    this.kindyService.alertSpecial++

    if (this.kindyService.alertSpecial > 3) {
      this.kindyService.alertSpecial = 1
    }
  }

  dismissModal() {
    this.modalController.dismiss()
  }

}
