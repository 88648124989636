import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-kein-account',
  templateUrl: './kein-account.page.html',
  styleUrls: ['./kein-account.page.scss'],
})
export class KeinAccountPage implements OnInit {

  constructor( private modalController : ModalController ) { }

  ngOnInit() {
  }

  dismissModal() { 
    this.modalController.dismiss()
  }

}
