
import { io } from 'socket.io-client';
import { AppConfig } from '../../app.config';

/**
 * Realtime Collab class
 */

export class GlooRtc {

  socket;
  _init = false
  userlist = []
  connected = false
  _rootscope = null;
  _tickerMap = {}
  _ticker = null
  inform = {}
  roomList = []

  constructor() {

  }

  init(url, params, callback) {

    let ref = this
    
    if (this.socket) {
      console.log("schon verbunden")
      return 
    }

    this.socket = io(AppConfig.socketEndpoint.replace("/api", ""), {
      transports: ['websocket'],
      query: {
        auth_token: params.auth_token,
        username: params.location_uid + ':' + params.userId
      },
      reconnection: true,             // Erlaubt Wiederverbindung
      //reconnectionAttempts: 5,        // Maximale Anzahl der Wiederverbindungsversuche
      reconnectionDelay: 1000,        // Anfängliche Verzögerung in Millisekunden
      reconnectionDelayMax: 5000,     // Maximale Verzögerung in Millisekunden
      randomizationFactor: 0.5
    });

    if (this._init) {
      return
    }

    this._init = true
  
    this.socket.on('connect', function () {
      console.log("____________socket connect", params)
      ref.connected = true

      callback({ event: 'status', data: { connected: ref.connected, event: 'connect' } })

      // Durchlaufen Sie alle Räume in roomList und treten Sie diesen bei
      ref.roomList.forEach(roomId => {
        ref.joinRoom(roomId, {});
      });
    });

    this.socket.on('event', function (data) {
      callback({ event: 'event', data: data })
    });

    this.socket.on('userlist', function (data) {
      ref.userlist = data
      callback({ event: 'userlist', data: data })
    });

    this.socket.on('chat_message', (data) => {
      console.log(`chat_message received:`, data);
      callback({ event: 'message', data: data })
    });

    this.socket.on('disconnect', function () {
      ref.connected = false
      callback({ event: 'status', data: { connected: ref.connected, event: 'disconnect'  } })
    });


    this.socket.on('reconnect', (attemptNumber) => {
      console.log(`Wiederverbunden nach ${attemptNumber} Versuchen`);
      // Durchlaufen Sie alle Räume in roomList und treten Sie diesen bei
      ref.roomList.forEach(roomId => {
        ref.joinRoom(roomId, {});
      });
    });

    this.socket.io.on('open', () => {
      console.log(`uccessful (re)connection`);
      //callback({ event: 'status', data: { connected: ref.connected } })
    })

    this.socket.io.on('reconnect', (attemptNumber) => {
      console.log(`Wiederverbunden nach ${attemptNumber} Versuchen`);

      callback({ event: 'status', data: { connected: ref.connected, event: `reconnect  ${attemptNumber} attempts` } })

      ref.roomList.forEach(roomId => {
        ref.joinRoom(roomId, {});
      });
    })

    this.socket.on('reconnect_attempt', () => {
      // Aktion bei jedem Wiederverbindungsversuch
      alert("reconnect_attempt")
    });

    this.socket.on('reconnect_failed', () => {
      // Aktion, wenn die Wiederverbindung fehlschlägt
    });

  }

  isConnected() {
    return this.connected
  }


  subscribe(key, callback) {
    this.inform[key] = callback
  }

  unsubscribe(key) {
    delete this.inform[key]
  }

  joinRoom(id, params) {

    if (this.socket && this.connected) {
      this.socket.emit('join_room', { id: id, params: params });

      // Fügen Sie den Raum zur roomList hinzu, wenn er noch nicht vorhanden ist
      if (!this.roomList.includes(id)) {
        this.roomList.push(id);
      }
    }
  }

  leaveRoom(id) {
    if (this.socket && this.connected) {
      this.socket.emit('leave_room', id);
      // Entfernen Sie den Raum aus der roomList
      this.roomList = this.roomList.filter(roomId => roomId !== id);
    }
  }

  sendMessage(message) {
    if (this.socket && this.connected) {
      this.socket.emit('chat_message', message);
    }
    else {
      alert("socket not connected")
    }
  }

  toggleConnection(con = true) {
    if (con) {
      this.socket.connect()
    }
    else {
      this.socket.disconnect()
    }
  }

}