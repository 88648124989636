import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, LoadingController, AlertController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { AbwesenheitArtPage } from '../abwesenheit-art/abwesenheit-art.page';
import { KindyService } from '../../services/kindy.service';
import * as moment from 'moment';
import { AbwesenheitKrankheitPage } from '../abwesenheit-krankheit/abwesenheit-krankheit.page';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-abwesenheit',
  templateUrl: './abwesenheit.page.html',
  styleUrls: ['./abwesenheit.page.scss'],
})
export class AbwesenheitPage implements OnInit {

  myReason:String;
  mySickReason:String;
  myDateFrom:any = new Date().toISOString();
  myDateTo:any  = new Date().toISOString();
  myDateFromStr;
  myDateToStr;
  isLoading:Boolean = false;
  deleteBtn:Boolean = false; 
  myMessage:Text;
  uplLoader;
  myReasonType;
  loaderTimer;
  prop;
  showDateFrom:Boolean=false;
  showDateTo:Boolean=false;
  showEdit:Boolean = false;
  editable:Boolean = true;
  customDayShortNames = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

  constructor( private modalController : ModalController,
               private navCtrl : NavController,
               private kindyService : KindyService,
               private translate: TranslateService,
               private activatedRoute : ActivatedRoute,
               private alertCtrl : AlertController,
               private loadingCtrl : LoadingController,
               private router : Router ) { }

  isModal:Boolean; 

  ngOnInit() {

    this.kindyService.current_absent = {}

    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    }   

    this.translate.get(['abholer.day1', 'abholer.day2', 'abholer.day3', 'abholer.day4', 'abholer.day5', 'abholer.day6', 'abholer.day7']).subscribe((res) => {
      this.customDayShortNames = [res["abholer.day1"], res["abholer.day2"], res["abholer.day3"], res["abholer.day4"], res["abholer.day5"], res["abholer.day6"], res["abholer.day7"]];
    })

    const id = this.activatedRoute.snapshot.paramMap.get('id');
    const absenceCat = this.activatedRoute.snapshot.paramMap.get('cat');

    let entry;

    if (absenceCat == 'archive') {
      entry = this.kindyService.findArchivedAbsentByUid(id)
    }
    else {
      entry = this.kindyService.findAbsentByUid(id)
    }

    if (entry) { 
      this.kindyService.current_absent = entry
      this.myDateTo = this.kindyService.current_absent.date_to //new Date(this.kindyService.current_absent.date_to);
      this.myDateFrom = this.kindyService.current_absent.date_from //new Date(this.kindyService.current_absent.date_from);
      this.deleteBtn = true
      this.myMessage = this.kindyService.current_absent.message

      this.editable = this.kindyService.current_absent.editable 
      //this.myDateToStr = this.convertDateToStr(this.myDateTo)
      //this.myDateFromStr = this.convertDateToStr(this.myDateFrom) 
    }
    else {
      this.myDateFrom = new Date().toISOString();
      //this.myDateFromStr = this.convertDateToStr(this.myDateFrom) 
    }

  }

  dismissModal() { 
    this.modalController.dismiss()
  }

  goBack() {
    this.navCtrl.back()
  }

  ionViewWillEnter() {    
    if (this.kindyService.current_absent) {
      this.myReason = this.kindyService.getAbsentName(this.kindyService.current_absent.reason)
      this.myReasonType = this.kindyService.current_absent.reason
      this.mySickReason = this.kindyService.getSicknessNameByUid(this.kindyService.current_absent.sickness)
    }
  }


  changeDate(cat) {
    if (cat=='from') {
      this.showDateFrom = !this.showDateFrom
      this.showDateTo = false
    }
    if (cat=='to') {
      this.showDateTo = !this.showDateTo
      this.showDateFrom = false
    }
  
  }


  hideDateTime() {
    this.showDateFrom = false
    this.showDateTo = false
  }


  async sicknessModal() {
    this.hideDateTime() 

    if (!this.editable) {
      return 
    }

    if (this.isModal) {
      const modal = await this.modalController.create({
        component: AbwesenheitKrankheitPage,
        animated: false,
        componentProps: { 
          isModal: true,
        }
      }); 
      modal.onDidDismiss().then( (data) => {
        this.mySickReason = this.kindyService.getSicknessNameByUid(this.kindyService.current_absent.sickness)
      }); 
      return await modal.present(); 
    }
    else {
      this.router.navigate(['abwesenheit-krankheit'])
    }
  }

  async artModal() {

    if (!this.editable) {
      return 
    }
    
    if (this.isModal) {
      const modal = await this.modalController.create({
        component: AbwesenheitArtPage,
        animated: false,
        componentProps: { 
          isModal: true,
        }
      }); 
      modal.onDidDismiss().then( (data) => {
        this.myReason = this.kindyService.getAbsentName(this.kindyService.current_absent.reason)
        this.myReasonType = this.kindyService.current_absent.reason
        this.mySickReason = ""
      }); 
      return await modal.present(); 
    }
    else {
      this.router.navigate(['abwesenheit-art'])
    }
  }

  async showLoader() {
    this.uplLoader = await this.loadingCtrl.create({
      message: ''
    });
    this.uplLoader.present()
  }

  async sendForm() {

    let form_data:any = {}

    let date_from = new Date(this.myDateFrom);
    let date_to = new Date(this.myDateTo);
    form_data.date_from = date_from.getDate()+'.'+ (date_from.getMonth()+1) + '.'+date_from.getFullYear();
    form_data.date_to = date_to.getDate()+'.'+ (date_to.getMonth()+1) + '.'+date_to.getFullYear();

    form_data.iso_from = this.myDateFrom
    form_data.iso_to = this.myDateTo
    form_data.reason = this.kindyService.current_absent.reason
    form_data.sickness = this.kindyService.current_absent.sickness
    form_data.message = this.myMessage //this.ipMessage["value"]   

    if (!form_data.date_to) {
      this.translate.get(['abwesenheit.label3', 'abholer.field1']).subscribe((res) => {
        this.kindyService.informAboutError(res["abwesenheit.label3"], res["abholer.field1"])
      })      
    }
    //else if ( moment(Date.parse(this.myDateFrom)).diff(moment(), 'days') < 0 ) {
    //  this.kindyService.informAboutError("Abwesend von", "Liegt in der Vergangenheit.")
    //}
    else if ( moment(Date.parse(this.myDateTo)).diff(moment(Date.parse(this.myDateFrom)), 'days') < 0 ) {
      this.translate.get(['abwesenheit.label3', 'abwesenheit.error1']).subscribe((res) => {
        this.kindyService.informAboutError(res["abwesenheit.label3"], res["abwesenheit.error1"])
      })
    }
    else if (!form_data.reason) {
      this.translate.get(['abwesenheit.label4', 'abholer.field1']).subscribe((res) => {
        this.kindyService.informAboutError(res["abwesenheit.label4"], res["abholer.field1"])
      })
    }
    else {

      await this.showLoader()  
      let ref = this
      
      this.loaderTimer = setTimeout( function() { ref.uplLoader.dismiss(); }, 30000);
      this.kindyService.apiCall("create_absent_entry", { "auth_token" : this.kindyService.usrAutToken(), "child_uid" : this.kindyService._currentChild, "usrId" : this.kindyService._usrId, "form_data" : form_data, "uid" : this.kindyService.current_absent.uid })
      .subscribe(data => {
        this.kindyService.setAbsentData((<any>data).obj.absences.list);  
        this.uplLoader.dismiss()
        clearTimeout(this.loaderTimer);
        this.translate.get(['saved']).subscribe((res) => {
          this.kindyService.presentToast(res["saved"], 1000)
        }) 
        if (this.isModal) {
          this.dismissModal()
        }
        else {
          this.kindyService.absences_cat = 'reload'
          this.navCtrl.back()
          //this.navCtrl.setDirection('root');
          //this.router.navigate(['/app/tabs/profile'])  
        } 
      },
      err => {
        this.uplLoader.dismiss()
        clearTimeout(this.loaderTimer);
        this.kindyService.informAboutError("","")
      });

    }

    //this.isLoading = true
  }

  async deleteForm() {
    let ref = this;


    this.translate.get(['abwesenheit.header', 'abwesenheit.label9', 'cancel', 'delete']).subscribe(async (res) => {

      let alert = await this.alertCtrl.create({
        header: res["abwesenheit.header"],
        message: res["abwesenheit.label9"],
        buttons: [
          {
            text: res["cancel"],
            role: 'cancel',
            handler: () => {
            } 
          },
          {
            text: res["delete"],
            handler: () => {

              this.showLoader()
          
              ref.loaderTimer = setTimeout( function() { ref.uplLoader.dismiss(); }, 30000);
              this.kindyService.apiCall("delete_absent_entry", { "auth_token" : this.kindyService.usrAutToken(), "usrId" : this.kindyService._usrId, "child_uid" : this.kindyService._currentChild, "uid" : this.kindyService.current_absent.uid })
              .subscribe(data => { 
                this.kindyService.setAbsentData((<any>data).obj.absences.list);  

                //console.log((<any>data).obj.absences)
                ref.uplLoader.dismiss()
                clearTimeout(ref.loaderTimer); 

                if (this.isModal) {
                  this.dismissModal()
                }
                else {
                  this.kindyService.absences_cat = 'reload'
                  this.navCtrl.back()
                  //this.navCtrl.setDirection('root');
                  //this.router.navigate(['/app/tabs/profile'])  
                } 
              },
              err => {
                ref.uplLoader.dismiss()
                clearTimeout(ref.loaderTimer);
                this.kindyService.informAboutError("","")
              });
            }
          }
        ]
      });
      alert.present();

    })
  }

}
