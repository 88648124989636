import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  { path: '', redirectTo: 'start', pathMatch: 'full' },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },

  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'nachrichten',
    loadChildren: () => import('./pages/nachrichten/nachrichten.module').then(m => m.NachrichtenPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./pages/profil/profil.module').then(m => m.ProfilPageModule)
  },
  {
    path: 'neuigkeiten-detail/:id',
    loadChildren: () => import('./pages/neuigkeiten-detail/neuigkeiten-detail.module').then(m => m.NeuigkeitenDetailPageModule)
  },
  {
    path: 'termine-detail/:id',
    loadChildren: () => import('./pages/termine-detail/termine-detail.module').then(m => m.TermineDetailPageModule)
  },
  {
    path: 'nachrichten-detail/:id',
    loadChildren: () => import('./pages/nachrichten-detail/nachrichten-detail.module').then(m => m.NachrichtenDetailPageModule)
  },
  {
    path: 'speiseplan',
    loadChildren: () => import('./pages/speiseplan/speiseplan.module').then(m => m.SpeiseplanPageModule)
  },
  {
    path: 'telefonliste',
    loadChildren: () => import('./pages/telefonliste/telefonliste.module').then(m => m.TelefonlistePageModule)
  },
  {
    path: 'einstellungen',
    loadChildren: () => import('./pages/einstellungen/einstellungen.module').then(m => m.EinstellungenPageModule)
  },
  {
    path: 'fotos',
    loadChildren: () => import('./pages/fotos/fotos.module').then(m => m.FotosPageModule)
  },
  {
    path: 'tagesverlauf',
    loadChildren: () => import('./pages/tagesverlauf/tagesverlauf.module').then(m => m.TagesverlaufPageModule)
  },
  {
    path: 'abholer',
    loadChildren: () => import('./pages/abholer/abholer.module').then(m => m.AbholerPageModule)
  },
  {
    path: 'abwesenheit/:cat/:id',
    loadChildren: () => import('./pages/abwesenheit/abwesenheit.module').then(m => m.AbwesenheitPageModule)
  },
  {
    path: 'mitteilung',
    loadChildren: () => import('./pages/mitteilung/mitteilung.module').then(m => m.MitteilungPageModule)
  },
  {
    path: 'abholer-rolle',
    loadChildren: () => import('./pages/abholer-rolle/abholer-rolle.module').then(m => m.AbholerRollePageModule)
  },
  {
    path: 'abwesenheit-art',
    loadChildren: () => import('./pages/abwesenheit-art/abwesenheit-art.module').then(m => m.AbwesenheitArtPageModule)
  },
  {
    path: 'abwesenheit-liste',
    loadChildren: () => import('./pages/abwesenheit-liste/abwesenheit-liste.module').then(m => m.AbwesenheitListePageModule)
  },
  {
    path: 'meine-kita',
    loadChildren: () => import('./pages/meine-kita/meine-kita.module').then(m => m.MeineKitaPageModule)
  },
  {
    path: 'meine-daten',
    loadChildren: () => import('./pages/meine-daten/meine-daten.module').then(m => m.MeineDatenPageModule)
  },
  {
    path: 'dokumente',
    loadChildren: () => import('./pages/dokumente/dokumente.module').then(m => m.DokumentePageModule)
  },
  {
    path: 'passwort-aendern',
    loadChildren: () => import('./pages/passwort-aendern/passwort-aendern.module').then(m => m.PasswortAendernPageModule)
  },
  {
    path: 'telefonliste-detail/:id',
    loadChildren: () => import('./pages/telefonliste-detail/telefonliste-detail.module').then(m => m.TelefonlisteDetailPageModule)
  },
  {
    path: 'passwort-vergessen',
    loadChildren: () => import('./pages/passwort-vergessen/passwort-vergessen.module').then(m => m.PasswortVergessenPageModule)
  },

  {
    path: 'login-kita',
    loadChildren: () => import('./pages/login-kita/login-kita.module').then(m => m.LoginKitaPageModule)
  },
  {
    path: 'login-kind',
    loadChildren: () => import('./pages/login-kind/login-kind.module').then(m => m.LoginKindPageModule)
  },
  {
    path: 'login-datenschutz',
    loadChildren: () => import('./pages/login-datenschutz/login-datenschutz.module').then(m => m.LoginDatenschutzPageModule)
  },
  {
    path: 'login-willkommen',
    loadChildren: () => import('./pages/login-willkommen/login-willkommen.module').then(m => m.LoginWillkommenPageModule)
  },
  {
    path: 'abwesenheit-krankheit',
    loadChildren: () => import('./pages/abwesenheit-krankheit/abwesenheit-krankheit.module').then(m => m.AbwesenheitKrankheitPageModule)
  },
  {
    path: 'login-standorte',
    loadChildren: () => import('./pages/login-standorte/login-standorte.module').then(m => m.LoginStandortePageModule)
  },
  {
    path: 'passwort-vergessen-standorte',
    loadChildren: () => import('./pages/passwort-vergessen-standorte/passwort-vergessen-standorte.module').then(m => m.PasswortVergessenStandortePageModule)
  },
  {
    path: 'special-info',
    loadChildren: () => import('./pages/special-info/special-info.module').then(m => m.SpecialInfoPageModule)
  },
  {
    path: 'neuigkeiten-anhang/:id',
    loadChildren: () => import('./pages/neuigkeiten-anhang/neuigkeiten-anhang.module').then(m => m.NeuigkeitenAnhangPageModule)
  },
  {
    path: 'nachricht-antworten',
    loadChildren: () => import('./pages/nachricht-antworten/nachricht-antworten.module').then( m => m.NachrichtAntwortenPageModule)
  },
  {
    path: 'kurzmitteilungen',
    loadChildren: () => import('./pages/kurzmitteilungen/kurzmitteilungen.module').then(m => m.KurzmitteilungenPageModule)
  },
  {
    path: 'kurzmitteilung-detail',
    loadChildren: () => import('./pages/kurzmitteilung-detail/kurzmitteilung-detail.module').then(m => m.KurzmitteilungDetailPageModule)
  },
  {
    path: 'speiseplan-pdf',
    loadChildren: () => import('./pages/speiseplan-pdf/speiseplan-pdf.module').then(m => m.SpeiseplanPdfPageModule)
  },
  {
    path: 'abfragelisten',
    loadChildren: () => import('./pages/abfragelisten/abfragelisten.module').then(m => m.AbfragelistenPageModule)
  },
  {
    path: 'abfrageliste-detail',
    loadChildren: () => import('./pages/abfrageliste-detail/abfrageliste-detail.module').then(m => m.AbfragelisteDetailPageModule)
  },
  {
    path: 'abholer-mitglied',
    loadChildren: () => import('./pages/abholer-mitglied/abholer-mitglied.module').then( m => m.AbholerMitgliedPageModule)
  },
  {
    path: 'testnachweis/:id',
    loadChildren: () => import('./pages/testnachweis/testnachweis.module').then( m => m.TestnachweisPageModule)
  },
  {
    path: 'testnachweis-liste',
    loadChildren: () => import('./pages/testnachweis-liste/testnachweis-liste.module').then( m => m.TestnachweisListePageModule)
  },
  {
    path: 'testnachweis-art',
    loadChildren: () => import('./pages/testnachweis-art/testnachweis-art.module').then( m => m.TestnachweisArtPageModule)
  },
  {
    path: 'testnachweis-ergebnis',
    loadChildren: () => import('./pages/testnachweis-ergebnis/testnachweis-ergebnis.module').then( m => m.TestnachweisErgebnisPageModule)
  },
  {
    path: 'anhaenge',
    loadChildren: () => import('./pages/anhaenge/anhaenge.module').then( m => m.AnhaengePageModule)
  },
  {
    path: 'kontoeinstellungen',
    loadChildren: () => import('./pages/kontoeinstellungen/kontoeinstellungen.module').then( m => m.KontoeinstellungenPageModule)
  },
  {
    path: 'sprachauswahl',
    loadChildren: () => import('./pages/sprachauswahl/sprachauswahl.module').then( m => m.SprachauswahlPageModule)
  },
  {
    path: 'login-sprache',
    loadChildren: () => import('./pages/login-sprache/login-sprache.module').then( m => m.LoginSprachePageModule)
  },
  {
    path: 'nachrichten-pinned',
    loadChildren: () => import('./pages/nachrichten-pinned/nachrichten-pinned.module').then( m => m.NachrichtenPinnedPageModule)
  },
  {
    path: 'zweifaktor',
    loadChildren: () => import('./pages/zweifaktor/zweifaktor.module').then( m => m.ZweifaktorPageModule)
  },
  {
    path: 'login-code',
    loadChildren: () => import('./pages/login-code/login-code.module').then( m => m.LoginCodePageModule)
  },
  {
    path: 'checkin',
    loadChildren: () => import('./pages/checkin/checkin.module').then( m => m.CheckinPageModule)
  },
  {
    path: 'nachrichten-anhang/:id',
    loadChildren: () => import('./pages/nachrichten-anhang/nachrichten-anhang.module').then( m => m.NachrichtenAnhangPageModule)
  },
  {
    path: 'welcome-sprache',
    loadChildren: () => import('./pages/welcome-sprache/welcome-sprache.module').then( m => m.WelcomeSprachePageModule)
  },
  {
    path: 'abfrageliste-auswahl',
    loadChildren: () => import('./pages/abfrageliste-auswahl/abfrageliste-auswahl.module').then( m => m.AbfragelisteAuswahlPageModule)
  },
  {
    path: 'abfrageliste-waehlen',
    loadChildren: () => import('./pages/abfrageliste-waehlen/abfrageliste-waehlen.module').then( m => m.AbfragelisteWaehlenPageModule)
  },
  {
    path: 'bilder-liste',
    loadChildren: () => import('./pages/bilder-liste/bilder-liste.module').then( m => m.BilderListePageModule)
  },
  {
    path: 'abfrageliste-info',
    loadChildren: () => import('./pages/abfrageliste-info/abfrageliste-info.module').then( m => m.AbfragelisteInfoPageModule)
  },
  {
    path: 'abwesenheit-archiv',
    loadChildren: () => import('./pages/abwesenheit-archiv/abwesenheit-archiv.module').then( m => m.AbwesenheitArchivPageModule)
  },
  {
    path: 'diskussion/:id',
    loadChildren: () => import('./pages/diskussion/diskussion.module').then( m => m.DiskussionPageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./pages/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'elternstunden',
    loadChildren: () => import('./pages/elternstunden/elternstunden.module').then( m => m.ElternstundenPageModule)
  },
  {
    path: 'elternstunden-detail/:cat/:id',
    loadChildren: () => import('./pages/elternstunden-detail/elternstunden-detail.module').then( m => m.ElternstundenDetailPageModule)
  }

  /*
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
