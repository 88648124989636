import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { File, FilePicker } from '@robingenz/capacitor-file-picker';
import { decode } from "base64-arraybuffer";
import { KindyService } from '../../services/kindy.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Events } from '../../services/events';
import { FileUploadService } from '../../services/file-upload.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';

import { Browser } from '@capacitor/browser';


@Component({
  selector: 'app-anhaenge',
  templateUrl: './anhaenge.page.html',
  styleUrls: ['./anhaenge.page.scss'],
})
export class AnhaengePage implements OnInit {

  isModal: Boolean;
  loadingFile;
  loaderTimer;
  uplkey: string;
  channel;
  socket;
  uplLoader;
  progress;
  uploading = false;
  attachmentList = [];
  category: 'evidence';
  maxFileUploadSize = 10;
  maxAttachments = 5;

  public formGroup = new UntypedFormGroup({
    types: new UntypedFormControl([]),
    multiple: new UntypedFormControl(false),
  });
  public files: File[] = [];


  constructor(
    private modalController: ModalController,
    private navCtrl: NavController,
    public kindyService: KindyService,
    private loadingCtrl: LoadingController,
    private translate: TranslateService,
    private platform: Platform,
    public http: HttpClient,
    private actionSheetController: ActionSheetController,
    private events: Events,
    public fileUploadService: FileUploadService,
    private alertCtrl: AlertController,
  ) {

    this.uplkey = this.kindyService.randomStr()

  }

  public uploadFile() {
    if (this.files.length > 0) {

      if ( this.files[0].size/1000000.0 > this.maxFileUploadSize ) {
        this.translate.get(['anhang.label6', 'ok']).subscribe(async (res) => {

          let alert = await this.alertCtrl.create({
            message: res["anhang.label6"],
            buttons: [
              {
                text: res["ok"],
                role: 'cancel',
                handler: () => {
                }
              },
            ]
          })

          alert.present()
        })

      }
      else {

        /*
        const blob = new Blob([new Uint8Array(decode(this.files[0].data))], {
          type: this.files[0].mimeType,
        });
        */

        const blob2 = this.b64toBlob(this.files[0].data, this.files[0].mimeType)

        this.uploadData(blob2, this.files[0].name, this.files[0].mimeType)
      }

    }
  }

  public async pickFile(): Promise<void> {
    const types = ["image/jpeg", "image/png", "application/pdf"];
    const multiple = false;

    this.files = []

    this.loadingFile = true

    try {
      const { files } = await FilePicker.pickFiles({ types, multiple });
      this.files = files;
      this.loadingFile = false
      this.uploadFile()
    }
    catch (e) {
      this.loadingFile = false
    }

  }

  async uploadData(blob, filename, mimetype) {

    const ref = this

    const url = this.kindyService.uploadUrl + "?storage_type=mobile&folder="+this.kindyService._usrId+"&uplkey=" + this.uplkey + "&loc=" + this.kindyService.location_detail["loc_id"];

    this.uploading = true

    this.translate.get(['profil.save']).subscribe(async (res) => {
      ref.uplLoader = await this.loadingCtrl.create({
        message: res["profil.save"],
      });
      await ref.uplLoader.present();

      ref.loaderTimer = setTimeout(function () { ref.uplLoader.dismiss(); }, 60000);

      this.fileUploadService.uploadFile(
        url,
        blob,
        filename
      ).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            console.log(`Uploaded! ${this.progress}%`);
            break;
          case HttpEventType.Response:

            //console.log('Upload successfully created!', event.body);

            let form_data = event.body
            form_data.cat = this.category

            this.kindyService.apiCall("mobile_upload", { "auth_token": this.kindyService.usrAutToken(), "child_uid": this.kindyService._currentChild, "usrId": this.kindyService._usrId, "form_data": form_data })
              .subscribe(data => {
                this.uploading = false

                if (data["obj"] && data["obj"].uid) {
                  event.body.file_uid = data["obj"].uid
                  this.attachmentList.push(event.body)

                  clearTimeout(ref.loaderTimer);
                  ref.uplLoader.dismiss()
                }


              },
                err => {

                });


            setTimeout(() => {
              this.progress = 0;
            }, 1500);
        }
      })
    })

    return

  }


  async presentActionSheet() {
    let buttons_list = []


    this.translate.get(['fotomodal.promptLabelPicture',
      'fotomodal.promptLabelPhoto',
      'fotomodal.prompResetPhoto',
      'fotomodal.promptLabelCancel',
      'fotomodal.promptLabelDocument']).subscribe(async (res) => {

          buttons_list.push({
            text: res["fotomodal.promptLabelDocument"],
            handler: () => {
              this.pickFile()
            }
          })

       if (this.platform.is('cordova')) {

          buttons_list.push({
            text: res["fotomodal.promptLabelPicture"],
            handler: () => {
              this.takePicture(CameraSource.Camera)
            }
          })

          buttons_list.push({
            text: res["fotomodal.promptLabelPhoto"],
            handler: () => {
              this.takePicture(CameraSource.Photos)
            }
          })

        }

        buttons_list.push({
          text: res["fotomodal.promptLabelCancel"],
          role: 'cancel',
          cssClass: 'btn-text-dark',
          handler: () => {
            //console.log('Cancel clicked');
          }
        })

        const actionSheet = await this.actionSheetController.create({
          buttons: buttons_list
        });
        await actionSheet.present();

      })
  }

  async _launchInAppBrowser(link: string) {
    await Browser.open({ url: link });
  }

  openFile(entry) {
    this._launchInAppBrowser(this.kindyService.attachurl+""+entry.file)
  }

  //convert base64 to blob
  b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


  async takePicture(source) {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source: source //CameraSource.Camera
    });

    const blob = new Blob([new Uint8Array(decode(image.base64String))], {
      type: `image/${image.format}`,
    });

    // ----

    const random = Math.floor(Math.random() * 100);
    this.uploadData(blob, "image_" + random + ".jpg", `image/${image.format}`)
  };


  removeEntry(entry) {

    const ref = this

    this.translate.get(['anhang.label1', 'profil.save', 'cancel', 'delete']).subscribe(async (res) => {

      let alert = await this.alertCtrl.create({
        message: res["anhang.label1"],
        buttons: [
          {
            text: res["cancel"],
            role: 'cancel',
            handler: () => {
            }
          },
          {
            text: res["delete"],
            handler: async () => {

              ref.uplLoader = await this.loadingCtrl.create({
                message: res["profil.save"],
              });
              await ref.uplLoader.present();

              ref.loaderTimer = setTimeout(function () { ref.uplLoader.dismiss(); }, 30000);
              this.kindyService.apiCall("remove_mobile_upload", { "auth_token": this.kindyService.usrAutToken(), "child_uid": this.kindyService._currentChild, "usrId": this.kindyService._usrId, "form_data": entry })
                .subscribe(data => {

                  clearTimeout(ref.loaderTimer);
                  ref.uplLoader.dismiss()

                  for (let k = 0; k < this.attachmentList.length; k++) {
                    if (this.attachmentList[k].file_uid == entry.file_uid) {
                      this.attachmentList.splice(k, 1)
                      return
                    }
                  }

                },
                  err => {

                  });

            }
          }]
        })

        alert.present()

      })


    }

  goBack() {

    this.kindyService.attachment_list = JSON.parse(JSON.stringify(this.attachmentList))
    if(this.isModal) {
      this.modalController.dismiss()
    }
    else {
      this.navCtrl.back()
    }
  }

  ngOnDestroy() {
    this.kindyService.attachment_list = null
  }

  ngOnInit() {

    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    }

    if(this.kindyService.attachment_list) {
      this.attachmentList = JSON.parse(JSON.stringify(this.kindyService.attachment_list))
    }

    if (this.attachmentList.length == 0) {
      this.presentActionSheet()
    }
  }

}


