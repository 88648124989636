import { GlooRtc } from "./app/classes/GlooRtc";
import { Events } from "./app/services/events";

export let AppGlobal: any = {
    rtcManager: new GlooRtc(),
    userList: [], 
    events: new Events()

}

export function generateUniqueId() {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    const randomChar = alphabet.charAt(randomIndex);
    /*
    return crypto.randomUUID()
    */
    // The prefix ensures that the ID starts with a letter, as IDs in HTML must start with a letter.
    const prefix = randomChar+'';
    // The suffix ensures that the ID ends with a unique string based on the current timestamp.
    const suffix = '' + Date.now().toString(36);

    // Generate a random string to use as the unique identifier.
    const randomString = Math.random().toString(36).substring(2, 8);

    // Combine the prefix, random string, and suffix to create the final UID.
    const uid = prefix + randomString + suffix;
    return uid;
}

export function generateReadableUID(chars = 8) {
    const allowedChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let uid = "";
    for (let i = 0; i < chars; i++) {
      uid += allowedChars.charAt(Math.floor(Math.random() * allowedChars.length));
    }
    return uid;
}