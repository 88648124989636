import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';



import { KeinAccountPage } from './pages/kein-account/kein-account.page';
import { ComponentsModule } from './components/components.module';
import { KindWechselnPage } from './pages/kind-wechseln/kind-wechseln.page';
import { AktivitaetenPage } from './pages/aktivitaeten/aktivitaeten.page';
import { AbholerPage } from './pages/abholer/abholer.page';
import { AbwesenheitPage } from './pages/abwesenheit/abwesenheit.page';
import { MitteilungPage } from './pages/mitteilung/mitteilung.page';
import { AbholerRollePage } from './pages/abholer-rolle/abholer-rolle.page';
import { AbwesenheitArtPage } from './pages/abwesenheit-art/abwesenheit-art.page';
import { AbwesenheitKrankheitPage } from './pages/abwesenheit-krankheit/abwesenheit-krankheit.page';



import { AutosizeModule } from 'ngx-autosize';
import { SpecialInfoPage } from './pages/special-info/special-info.page';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
//import { Calendar } from '@ionic-native/calendar/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
//import { StreamingMedia } from '@ionic-native/streaming-media/ngx';

const config: SocketIoConfig = { url: 'http://localhost:3444', options: {} };

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent,
        KeinAccountPage,
        KindWechselnPage,
        MitteilungPage,
        AktivitaetenPage,
        //Autosize3
    ],
    imports: [BrowserModule,
        IonicModule.forRoot({
        //mode: 'ios' 
        }),
        SocketIoModule.forRoot(config), 
        NgxIonicImageViewerModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ComponentsModule,
        AutosizeModule,
        FormsModule
    ],
    providers: [
        //ScreenOrientation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        //Calendar,
        HTTP,
        //StreamingMedia,
        //{provide: ErrorHandler, useClass: AirbrakeErrorHandler}
    ],
    //providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
    bootstrap: [AppComponent]
})
export class AppModule { }
