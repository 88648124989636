
function broofa() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const AppConfig: any = {
  // ___Socket___
  socketEndpoint: 'https://app.kindy.cloud',                          // Live
  //socketEndpoint: "http://127.0.0.1:3209",
  //socketEndpoint: 'http://192.168.178.34:3209',                     // Büro
  //socketEndpoint: "http://192.168.178.97:3209",                     // Home

  // --------------------------------------------
  // ___API___
  apiEndpoint: 'https://app.kindy.cloud/api/mobile-api',              // Live
  //apiEndpoint: 'http://127.0.0.1:5021/api/mobile-api',              // local
  //apiEndpoint: 'http://192.168.178.34:5021/api/mobile-api',         // Büro
  //apiEndpoint: 'http://192.168.178.25:5021/api/mobile-api',         // Home
  //apiEndpoint: 'https://app-06108.kindy.cloud/api/mobile-api',      // Live (Halle)
  //apiEndpoint: 'https://app.kindy-junior.de/api/mobile-api',        // Stage

  // --------------------------------------------
  // ___Upload___
  uploadEndpoint: 'https://app.kindy.cloud/momentup/upload',               // Live
  //uploadEndpoint: 'http://192.168.178.34:9000/upload',                   // Büro
  //uploadEndpoint: 'http://192.168.178.97:3200//upload',                  // Home
  //uploadEndpoint: 'https://app.kindy-junior.de/momentup/upload',         // Stage
  // --------------------------------------------

  production: true,
  version: "1.4.14",
  lastUpdate: "18.01 - 15:00",
  appSessionId: broofa(),
  pricing: 25,
}


