import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { KindyService } from '../../services/kindy.service';

@Component({
  selector: 'app-abwesenheit-krankheit',
  templateUrl: './abwesenheit-krankheit.page.html',
  styleUrls: ['./abwesenheit-krankheit.page.scss'],
})
export class AbwesenheitKrankheitPage implements OnInit {

  isModal:Boolean; 
  sickness_reason:string;
  
  constructor( private modalController : ModalController,
               public kindyService : KindyService,
               private navCtrl : NavController ) { }

  ngOnInit() { 
    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    }

    if (this.kindyService.current_absent) {
      this.sickness_reason = this.kindyService.current_absent.sickness
    }

  }

  goBack() { 
    if (this.isModal) {
      this.modalController.dismiss()
    }
    else {
      this.navCtrl.back()
    }
  }

  chooseReasen(type) {
    this.kindyService.current_absent.sickness = type
    this.goBack()
  }

}
