import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController, ActionSheetController, Platform, ToastController, NavController, ModalController } from '@ionic/angular';
import { timeout } from 'rxjs/operators';
import { Preferences } from '@capacitor/preferences';
import { HTTP } from '@ionic-native/http/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Capacitor } from '@capacitor/core';
import { from } from 'rxjs';
import { AppConfig } from '../../app.config';
import { AppGlobal } from '../../app.global';
import { CryptoService } from './crypto.service';
import { Badge } from '@capawesome/capacitor-badge';

/*
import 'moment/src/locale/de';
import 'moment/src/locale/es';
import 'moment/src/locale/fr';
import 'moment/src/locale/uk';
import 'moment/src/locale/da';
import 'moment/src/locale/it';
*/

@Injectable({
  providedIn: 'root'
})
export class KindyService {

  actVersion = AppConfig.version

  debugMode = false

  //customerName = 'halle'
  customerName = 'kindy'   //—Kindy

  //apiUrl = 'http://127.0.0.1:5021/api/mobile-api'             // local allgemein
  //apiUrl = 'http://192.168.178.24:5021/api/mobile-api';       // büro
  //apiUrl = 'http://192.168.178.97:5021/api/mobile-api';       // zu hause
  //apiUrl = 'https://app-06108.kindy.cloud/api/mobile-api';    // live - halle
  //apiUrl = 'https://app.kindy.cloud/api/mobile-api';            // live

  //apiUrl = 'https://app.kindy-junior.de/api/mobile-api';      // stage

  //uploadUrl = 'http://192.168.178.24:9000/upload';            // büro
  //uploadUrl = 'http://192.168.178.97:3200//upload';           // zu hause
  //uploadUrl = 'https://app.kindy.cloud/momentup/upload';      // live
  //uploadUrl = 'https://app.kindy-junior.de/momentup/upload';    // stage

  downloadImageUrl = 'https://app.kindy.cloud/momentup/ff-download?key='

  apiUrl = AppConfig.apiEndpoint
  uploadUrl = AppConfig.uploadEndpoint

  _usrAutToken = null
  _usrId = 0
  _locUid = null
  _currentChild = null
  _currentLocation = null
  _childList = null
  _childs = []
  childname = ""
  _activities = []
  _kitalists = []
  unread_activities = 0
  login_token = null
  location_detail = {
    diet_info: {},
    address: "",
    phone: "",
    email: "",
    name: "",
    docs: {},
    management: {
      pic: "",
      name: ""
    }
  }
  user_detail = {
    name: "",
    email: ""
  }

  _demo = false
  _translate = false

  setup_stamp;
  news_stamp;

  onboard_loc;
  onboard_pwd;
  onboard_childs;
  onboard_parents;
  onboard_welcome = [];

  _modal_open = false;

  kitalists_count = 0;
  kitalist_loaded = null
  kita_list = null

  image_list = []

  session_email;

  fbase_token;
  news_skip;
  news_streamData;
  news_more;
  events_data;
  events_skip;
  events_more;
  events_past_data;
  events_past_skip;
  events_past_more;
  messages_data;
  messages_skip;
  messages_more;
  messages_unread;
  messages_read;

  archived_messages_data = [];
  archived_messages_skip;
  archived_messages_more;
  archived_messages_loaded = false

  parenting_hours_data = [];
  parenting_hours_cat = 'active'
  parenting_hours_loaded = false
  parenting_hours_sum = 0

  absent_data = [];
  absences_cat = 'active'

  archived_absences_loaded = false
  archived_absences_data = [];
  archived_absences_skip;
  archived_absences_more;

  evidence_data = [];
  collect_data;
  shortmessage_data;
  shortmessage_list;
  messages_stamp;
  photo_list;
  _usrLang = 'de';
  attachurl;

  _privateKey;
  _publicKey;

  discussions_list;
  disussions_loaded = {}
  discussions_last_seq = {};

  discussions_count = {}
  discussions_more = {}
  discussions_skip = {}

  attachment_list;
  attachment_editable;

  attach_detail_list;

  route_param = null;

  react_list;
  lng_list;
  sickness_list = {};
  settings_data = {
    short_message: true,
    phone_list: true,
    collector: false,
    show_daily: true,
    show_meal: true,
    show_phonelist: true,
    show_moments: true,
    absent: true,
    evidence: [],
    email_notification: false,
    parenting_hours: false,
    hide_like: false,
    settings_menu: "text",
    photos: true
  }
  absent_evidence = [];
  notiParams;
  _getLangSelected = null;

  loader;

  news_activites = {};
  events_activites = {};
  messages_activites = {};

  diet_current = []
  diet_next = []
  diet_weeks = []

  diets_files = []
  pickup_member = []

  tracking_list = []
  tracking_list2 = []
  tracking_skip;
  tracking_more = false;
  tracking_stamp;

  current_absent;
  current_evidence;

  phone_list;
  phone_list_count = 0;

  evidence_types = []
  evidence_result = []

  evidence_result_map = {}
  evidence_types_map = {}
  weekdays_map = {}
  absence_types_map = {}

  lang_selected = false;

  lastModalOpen;


  alertSpecial = 1;

  constructor(public http: HttpClient,
    private alertCtrl: AlertController,
    //private fbase : Firebase,
    private modalController: ModalController,
    private navCtlr: NavController,
    private translate: TranslateService,
    private actionSheetCtrl: ActionSheetController,
    private platform: Platform,
    private httpNative: HTTP,

    private toastController: ToastController) {
    //Preferences.set('name', 'Max');

    this.lastModalOpen = Math.floor(Date.now() / 1000)

  }


  setup() {
    this.translate.get(['testnachweis.result1', 'testnachweis.result2', 'testnachweis.typ1', 'testnachweis.typ2', 'testnachweis.typ3',
      'abwesenheit.absence1', 'abwesenheit.absence2', 'backend.activity7', 'backend.activity9', 'abwesenheit.absence3', 'abwesenheit.absence4', 'week.day1', 'week.day2', 'week.day3', 'week.day4', 'week.day5', 'week.day6', 'week.day7']).subscribe(async (res) => {
        this.evidence_result.push(res["testnachweis.result1"], res["testnachweis.result2"])
        this.evidence_types.push(res["testnachweis.typ1"], res["testnachweis.typ2"], res["testnachweis.typ3"])

        this.evidence_result_map['result1'] = res["testnachweis.result1"]
        this.evidence_result_map['result2'] = res["testnachweis.result2"]

        this.evidence_types_map['type1'] = res["testnachweis.typ1"]
        this.evidence_types_map['type2'] = res["testnachweis.typ2"]
        this.evidence_types_map['type3'] = res["testnachweis.typ3"]

        this.absence_types_map['krank'] = res["abwesenheit.absence1"]
        this.absence_types_map['zuhause'] = res["abwesenheit.absence2"]
        this.absence_types_map['urlaub'] = res["abwesenheit.absence3"]
        this.absence_types_map['sonstiges'] = res["abwesenheit.absence4"]

        this.absence_types_map['entschuldigt'] = res["backend.activity9"]
        this.absence_types_map['unentschuldigt'] = res["backend.activity7"]

        this.weekdays_map["day1"] = res["week.day1"]
        this.weekdays_map["day2"] = res["week.day2"]
        this.weekdays_map["day3"] = res["week.day3"]
        this.weekdays_map["day4"] = res["week.day4"]
        this.weekdays_map["day5"] = res["week.day5"]
        this.weekdays_map["day6"] = res["week.day6"]
        this.weekdays_map["day7"] = res["week.day7"]
      })
  }

  modalOpen(v) {
    this._modal_open = v
  }

  setAbsentData(data) {
    this.absent_data = data
  }

  setEvidenceData(data) {
    this.evidence_data = data
    this.prepareAbsenceList()
  }

  absentToday() {
    if (this.absent_data) {
      for (var i in this.absent_data) {
        if (this.absent_data[i]["today"]) {
          return true;
        }
      }
    }
    return false;
  }

  isLoggedIn() {

    //this.Preferences.set('kindyAuthToken', 'XY123')
    // Or to get a key/value pair

    return true;
  }

  usrAutToken() {
    return this._usrAutToken;
  }

  async getUserLang() {

    try {
      let usrlang = await Preferences.get({ key: 'kindyUserLang' });
      if (usrlang && usrlang.value) {
        this._usrLang = usrlang.value
        return usrlang.value
      }
      this._usrLang = 'de'
      return 'de'
    }
    catch (e) {
      this._usrLang = 'de'
      return 'de'
    }

  }

  async setUserLang(lang) {
    this._usrLang = lang

    await Preferences.set({
      key: 'kindyUserLang',
      value: lang
    })
  }

  async setAuthToken(auth_token) {
    this._usrAutToken = auth_token

    await Preferences.set({
      key: 'kindyAuthToken',
      value: auth_token
    })
  }

  async getLangSeleted() {
    if (this._getLangSelected) {
      return this._getLangSelected
    }

    let getLangSeleted = await Preferences.get({ key: 'kindyLangSeleted' });
    if (getLangSeleted.value) {
      return getLangSeleted.value
    }
    return
  }

  async setLangSelected(val) {
    this._getLangSelected = val

    console.log(val)
    await Preferences.set({
      key: 'kindyLangSeleted',
      value: val
    })
  }

  async getAuthToken() {
    let kindyAuthToken = await Preferences.get({ key: 'kindyAuthToken' });
    if (kindyAuthToken.value) {
      return kindyAuthToken.value
    }
    return
  }

  async setCurrentChild(child_uid) {
    this._currentChild = child_uid
    await Preferences.set({
      key: 'kindyCurrentChild',
      value: child_uid
    })
  }

  async setCurrentLocation(location_uid) {
    this._currentLocation = location_uid
    this._locUid = location_uid
    await Preferences.set({
      key: 'kindyCurrentLocation',
      value: location_uid
    })
  }

  async getNotiParams() {
    let ret = await Preferences.get({ key: 'kindyNotiParams' })
    if (ret.value) {
      return JSON.parse(ret.value);
    }
    return
  }

  async setNotiParams(cat, entry_uid, child_uid, location_uid) {
    if (cat && entry_uid) {
      await Preferences.set({
        key: 'kindyNotiParams',
        value: JSON.stringify({ cat: cat, entry_uid: entry_uid, child_uid: child_uid, location_uid: location_uid })
      });
    } else {
      await Preferences.remove({ key: 'kindyNotiParams' })
    }

  }

  async getCurrentChild() {
    let entry = await Preferences.get({ key: 'kindyCurrentChild' })
    if (entry.value) {
      return entry.value
    }
    return
  }

  async getCurrentLocation() {
    let entry = await Preferences.get({ key: 'kindyCurrentLocation' })
    if (entry.value) {
      return entry.value
    }
    return
  }

  async removeAuthToken() {
    await Preferences.remove({ key: 'kindyAuthToken' })
    await Preferences.remove({ key: 'kindyCurrentChild' })
    //this.navCtrl.setRoot('WelcomePage')
  }

  updateAppStatus(isActive: any) {

    if (this.usrAutToken() && this._usrId) {
      this.apiCall("update_status", {
        "auth_token": this.usrAutToken(),
        "child_uid": this._currentChild,
        "usrId": this._usrId,
        "active": isActive,
        "fbase_token": this.fbase_token
      })
        .subscribe(data => {
          //console.log("setup", data);
          // In den zweiten Tab wechseln
          //this.app.getRootNav().getActiveChildNav().select(1)
        },
          err => {
            //this.connectionIssues = true
          },
          () => {
            // this works!
          })
    }

  }

  getStream(location, skip) {
    var data: any = {}
    data.location = location
    data.skip = skip

    return new Promise((resolve, reject) => {

      this.http.post(this.apiUrl, '', {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        params: { ea: "stream", data: JSON.stringify(data) }
      }
      )
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }


  prepareAbsenceList() {
    moment.locale(this._usrLang);

    if (this.absent_data && this.absent_data.length > 0) {
      for (var i in this.absent_data) {
        this.absent_data[i].short_day = moment(Date.parse(this.absent_data[i]["date_from"])).format('DD')
        this.absent_data[i].short_month = moment(Date.parse(this.absent_data[i]["date_from"])).format('MMM')
      }
    }

    if (this.archived_absences_data && this.archived_absences_data.length > 0) {
      for (var i in this.archived_absences_data) {
        this.archived_absences_data[i].short_day = moment(Date.parse(this.archived_absences_data[i]["date_from"])).format('DD')
        this.archived_absences_data[i].short_month = moment(Date.parse(this.archived_absences_data[i]["date_from"])).format('MMM')
      }
    }


  }

  prepareEventList() {
    moment.locale(this._usrLang);

    for (var i in this.events_data) {

      this.events_data[i].date_period = moment(this.events_data[i]["date_from"], 'DD.MM.YYYY').format('ddd, D.MM.YY')

      if (!this.events_data[i]["all_day"] && this.events_data[i]["deliver_hour_str"] && this.events_data[i]["deliver_hour_str"].length > 0) {
        this.events_data[i].date_period += " (" + moment(this.events_data[i]["deliver_hour_str"], 'hh:mm').format('HH:mm')
      }

      if (this.events_data[i]["date_to"] && this.events_data[i]["date_to"] != this.events_data[i]["date_from"]) {
        if (!this.events_data[i]["all_day"]) {
          this.events_data[i].date_period += ") "
        }
        this.events_data[i].date_period += " - " + moment(this.events_data[i]["date_to"], 'DD.MM.YYYY').format('ddd, D.MM.YY')

        if (!this.events_data[i]["all_day"] && this.events_data[i]["deliver_hour2_str"] && this.events_data[i]["deliver_hour2_str"].length > 0) {
          this.events_data[i].date_period += " (" + moment(this.events_data[i]["deliver_hour2_str"], 'hh:mm').format('HH:mm') + ")"
        }
      }
      else {
        if (!this.events_data[i]["all_day"] && this.events_data[i]["deliver_hour2_str"] && this.events_data[i]["deliver_hour2_str"].length > 0) {
          this.events_data[i].date_period += " - " + moment(this.events_data[i]["deliver_hour2_str"], 'hh:mm').format('HH:mm') + ")"
        }
      }

      this.events_data[i].short_day = moment(this.events_data[i]["date_from"], 'DD.MM.YYYY').format('DD')
      this.events_data[i].short_month = moment(this.events_data[i]["date_from"], 'DD.MM.YYYY').format('MMM')
      this.events_data[i].days_ago = moment(this.events_data[i]["date_from"], 'DD.MM.YYYY').fromNow()

      if (!this.events_data[i]["all_day"] && this.events_data[i]["deliver_hour_str"] && this.events_data[i]["deliver_hour_str"].length > 0) {
        this.events_data[i].days_ago = moment(this.events_data[i]["date_from"] + " " + this.events_data[i]["deliver_hour_str"], 'DD.MM.YYYY HH:mm').fromNow()
      }

    }

    for (var i2 in this.events_past_data) {
      this.events_past_data[i2].date_period = moment(this.events_past_data[i2]["date_from"], 'DD.MM.YYYY').format('ddd, DD.MM.YY')
      if (this.events_past_data[i2]["date_to"] && this.events_past_data[i2]["date_to"] != this.events_past_data[i2]["date_from"]) {
        this.events_past_data[i2].date_period += " - " + moment(this.events_past_data[i2]["date_to"], 'DD.MM.YYYY').format('ddd, DD.MM.YY')
      }

      this.events_past_data[i2].short_day = moment(this.events_past_data[i2]["date_from"], 'DD.MM.YYYY').format('DD')
      this.events_past_data[i2].short_month = moment(this.events_past_data[i2]["date_from"], 'DD.MM.YYYY').format('MMM')
      this.events_past_data[i2].days_ago = moment(this.events_past_data[i2]["date_from"], 'DD.MM.YYYY').fromNow()

      if (!this.events_past_data[i2]["all_day"] && this.events_past_data[i2]["deliver_hour_str"] && this.events_past_data[i2]["deliver_hour_str"].length > 0) {
        this.events_past_data[i2].days_ago = moment(this.events_past_data[i2]["date_from"] + " " + this.events_past_data[i2]["deliver_hour_str"], 'DD.MM.YYYY HH:mm').fromNow()
      }
    }

    for (let key in this.events_activites) {
      this.events_activites[key]["date_period"] = moment(this.events_activites[key]["date_from"], 'DD.MM.YYYY').format('ddd, DD.MM.YY')
      if (this.events_activites[key]["date_to"] && this.events_activites[key]["date_to"] != this.events_activites[key]["date_from"]) {
        this.events_activites[key]["date_period"] += " - " + moment(this.events_activites[key]["date_to"], 'DD.MM.YYYY').format('ddd, DD.MM')
      }
      this.events_activites[key]["short_day"] = moment(this.events_activites[key]["date_from"], 'DD.MM.YYYY').format('DD')
      this.events_activites[key]["short_month"] = moment(this.events_activites[key]["date_from"], 'DD.MM.YYYY').format('MMM')
      this.events_activites[key]["days_ago"] = moment(this.events_activites[key]["date_from"], 'DD.MM.YYYY').fromNow()
    }
  }

  pinnedMessage(uid, pinned) {
    for (var i in this.messages_data) {
      if (this.messages_data[i].uid == uid) {
        this.messages_data[i].pinned = pinned
        return
      }
    }
  }

  sortMessagesList() {

    if (!this.messages_data) {
      return
    }

    this.messages_data = this.messages_data.sort(function (a, b) {

      var o1 = b["pinned"] ? 1 : 0;
      var o2 = a["pinned"] ? 1 : 0;

      var p1 = b["stamp"];
      var p2 = a["stamp"];

      if (o1 < o2) return -1;
      if (o1 > o2) return 1;
      if (p1 < p2) return -1;
      if (p1 > p2) return 1;
      return 0;

    });
  }

  prepareMessagesList() {
    this.messages_unread = []
    this.messages_read = false
    for (var i in this.messages_data) {
      if (!this.messages_data[i].usr_read) {
        this.messages_unread.push(this.messages_data[i])
      } else {
        this.messages_read = true
      }
    }
  }

  setDiet(diets) {
    this.diet_current = diets["cweek"]
    this.diet_next = diets["nweek"]
    this.diet_weeks = diets["weeks"]
  }

  setTracking(tracking, skip, more) {
    this.tracking_list = tracking
    this.tracking_skip = skip
    this.tracking_more = more
  }

  convertTracking() {
    if (this.tracking_list2) {
      let date_map: any = {}

      this.tracking_list2.forEach(function (value) {
        if (!date_map[value.from]) {
          date_map[value.from] = []
        }

        date_map[value.from].push(value)
      });

      let date_list = []

      for (var k in date_map) {
        date_list.push({ date: k, list: date_map[k] })
      }

      this.tracking_list = date_list
    }


  }

  hasTracking() {

    if (!this.tracking_list || this.tracking_list.length == 0) {
      return false;
    }
    return true;
  }

  hasArchivedAbsences() {
    if (!this.archived_absences_data || this.archived_absences_data.length == 0) {
      return false
    }
    return true;
  }

  hasArchivedMessages() {
    if (!this.archived_messages_data || this.archived_messages_data.length == 0) {
      return false
    }
    return true;
  }

  hasMessages() {
    if (!this.messages_data || this.messages_data.length == 0) {
      return false
    }
    return true;
  }

  hasNews() {
    if (!this.news_streamData || this.news_streamData.length == 0) {
      return false
    }
    return true;
  }

  hasEvents() {
    if (!this.events_data || this.events_data.length == 0) {
      return false
    }
    return true;
  }

  hasActivities() {
    if (!this._activities || this._activities.length == 0) {
      return false
    }
    return true;
  }

  async isUnreadEntry(entry_uid) {
    if (this._activities) {
      for (var i in this._activities) {
        if (this._activities[i].entry_uid == entry_uid) {
          if (!this._activities[i].read) {
            this._activities[i].read = true
            this.apiCall("activity_read", { "auth_token": this.usrAutToken(), "child_uid": this._currentChild, "usrId": this._usrId, "entry_uid": entry_uid })
              .subscribe(data => {
              },
                (err) => console.info(err)
              );
          }
          await this.countUnreadActivities()
          return true
          //return this._activities[i]
        }
      }
    }
    return null
  }

  getUnreadActivities() {
    let list = []
    for (var i in this._activities) {
      if (!this._activities[i]["read"]) {
        list.push(this._activities[i])
      }
    }
    this.unread_activities = list.length
    return list
  }

  async countUnreadActivities() {
    this.unread_activities = this.getUnreadActivities().length
    if (this.platform.is('cordova')) {
      await Badge.set({ count: this.unread_activities })
    }
  }

  async unreadActivitiesCount() {
    if (this.platform.is('cordova')) {
      // await Badge.set({count: 0})
      //  this.fbase.setBadgeNumber(this.unread_activities)
      //this.fbase.setBadgeNumber(0)
    }
    return this.unread_activities
  }

  getReadActivities() {
    let list = []
    for (var i in this._activities) {
      if (this._activities[i]["read"]) {
        list.push(this._activities[i])
      }
    }
    return list
  }

  apiCall(action, data) {
    /*
    //return new Promise((resolve, reject) => {
      console.log(JSON.stringify(data))
      this.http.post(this.apiUrl, '',{
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        params: {ea: action, data: JSON.stringify(data)} }
      )
        .timeout(3000, new Error('timeout exceeded'))
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
    */

    data.loc = this._locUid
    data.appvs = this.actVersion
    data.apivs = 2
    data.applang = this._usrLang //this.translate.currentLang

    let postData = new FormData();
    postData.append('ea', action);
    postData.append('data', JSON.stringify(data))

    if (Capacitor.isNativePlatform()) {

      return from(this.httpNative.post(this.apiUrl, {
        ea: action,
        data: JSON.stringify(data)
      }, {})
        .then((response) => {
          let mm = JSON.parse(response.data)
          //alert(JSON.stringify(mm))
          return mm
        })
        .catch((error) => {
          return error
          //alert("error "  + JSON.stringify(error))
        })).pipe(timeout(30000))

    }
    else {
      return this.http.post(this.apiUrl, postData).pipe(timeout(30000))
    }

    /*
       return this.http.post(this.apiUrl, '',{
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        params: {ea: action, data: JSON.stringify(data)}
       }).timeout(10000)
    */
  }

  checkShortMessageRead(location_uid, list) {
    var data: any = {}

    data.location_uid = location_uid
    data.list = list

    return this.http.post(this.apiUrl, '', {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      params: { ea: "shortmessageread", data: JSON.stringify(data) }
    }
    )

  }


  setSickness(list) {
    list.sort((a, b) => (a.name > b.name) ? 1 : -1)

    this.sickness_list = {}
    let cc = 1

    for (let k of list) {
      k.idx = cc
      cc++

      if (!this.sickness_list[k.name.charAt(0).toUpperCase()]) {
        this.sickness_list[k.name.charAt(0).toUpperCase()] = { cat: k.name.charAt(0).toUpperCase(), list: [] }
      }
      this.sickness_list[k.name.charAt(0)].list.push(k)
    }
  }

  /*
  login(data) {
    return new Promise((resolve, reject) => {
      //console.log(JSON.stringify(data))
      this.http.post(this.apiUrl + '/login', '', {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        params: { 'pin': data.pin, 'location': data.location }
      }
      )
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  */

  /*
  addUser(data) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + '/users', JSON.stringify(data))
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }
  */

  setActivities(acts) {
    this._activities = acts
  }

  async informAboutError(title, message) {

    this.translate.get(['checkInternet']).subscribe(async (res) => {

      if (!message || message.length < 2) {
        message = res['checkInternet']
      }

      if (!title || title.length < 2) {
        //title = "Es ist ein Fehler aufgetreten."
        const alert = await this.alertCtrl.create({
          message: message,
          buttons: ['OK']
        });
        alert.present();
        return
      }
      else {
        const alert = await this.alertCtrl.create({
          header: title,
          message: message,
          buttons: ['OK']
        });
        alert.present();
        return
      }

    })

  }

  getEvidenceResultByUid(type) {
    let nn = {
      'result1': this.evidence_result[0],
      'result2': this.evidence_result[1]
    }

    if (nn[type]) {
      return nn[type];
    }

    return null;
  }

  getEvidenceNameByUid(type) {
    let nn = {
      'type1': this.evidence_types[0],
      'type2': this.evidence_types[1],
      'type3': this.evidence_types[2]
    }

    if (nn[type]) {
      return nn[type];
    }

    return null;
  }

  getEvidenceDate(entry) {

    if (entry.date_from) {
      return moment(Date.parse(entry.date_from)).format('DD.MM.YYYY')
    }
    return ""
  }

  getEvidenceIcon(i) {

    const l = [2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1]

    return '/assets/img/icon/icon-menue-type' + l[i] + '.svg'
  }

  getEvidenceType(type) {

    if (this.evidence_types_map[type]) {
      return this.evidence_types_map[type];
    }
    return "";
  }

  getAbsentName(type) {

    let nn = {
      'krank': this.absence_types_map['krank'],
      'zuhause': this.absence_types_map['zuhause'],
      'urlaub': this.absence_types_map['urlaub'],
      'sonstiges': this.absence_types_map['sonstiges'],
      'entschuldigt': this.absence_types_map['entschuldigt'],
      'unentschuldigt': this.absence_types_map['unentschuldigt']
    }
    if (nn[type]) {
      return nn[type];
    }
    return null;

  }

  archiveOrUnarchive(message_uid, archive) {

    if (archive) {
      for (let k = 0; k < this.messages_data.length; k++) {
        if (this.messages_data[k].uid == message_uid) {
          if (this.archived_messages_loaded) {
            this.messages_data[k].archived = true
            this.messages_data[k].pinned = false
            this.archived_messages_data.push(this.messages_data[k])
          }
          this.messages_data.splice(k, 1)
          break
        }
      }
    }
    else {
      for (let k = 0; k < this.archived_messages_data.length; k++) {
        if (this.archived_messages_data[k].uid == message_uid) {
          this.archived_messages_data[k].archived = false
          this.messages_data.push(this.archived_messages_data[k])
          this.archived_messages_data.splice(k, 1)
          break
        }
      }
    }

  }

  /*
  removeMessageFromList(message_uid) {
    for (let k = 0; k < this.messages_data.length; k++) {
      if (this.messages_data[k].uid == message_uid) {
        this.messages_data.splice(k, 1)
        break
      }
    }
  }
  */

  updateMessageRead(message_uid, val) {
    for (let k = 0; k < this.messages_data.length; k++) {
      if (this.messages_data[k].uid == message_uid) {
        if (this.messages_data[k].reply_message) {
          this.messages_data[k].reply_message.receiver_read = val
        }
        break
      }
    }
  }

  updateMessageReply(message_uid, data) {
    for (let k = 0; k < this.messages_data.length; k++) {
      if (this.messages_data[k].uid == message_uid) {
        this.messages_data[k].reply_message = data
        break
      }
    }
  }

  updateMessageListRead(uid, val) {
    for (let k = 0; k < this.shortmessage_list.length; k++) {
      if (this.shortmessage_list[k].uid == uid) {
        this.shortmessage_list[k].stamp_time = val["stamp_time"]
        this.shortmessage_list[k].receiver_read = val["receiver_read"]
        this.shortmessage_list[k].stamp_day = val["stamp_day"]
        break
      }
    }
  }

  getAbsentDate(entry) {
    let str = ""
    let dfrom = Date.parse(entry.date_from)

    if (entry.date_to.length < 2) {
      entry.date_to = entry.date_from
    }

    let dto = Date.parse(entry.date_to)

    str = moment(Date.parse(entry.date_from)).format('DD.MM.YYYY')
    //moment(this.events_data[i]["date_from"], 'L', 'de').format('ddd, D. MMMM YY')

    if (dfrom != dto) {
      str += " - " + moment(Date.parse(entry.date_to)).format('DD.MM.YYYY')
    }

    return str
  }


  fbaseToken(token) {
    //console.log("ja ein token", token)
    this.fbase_token = token

    if (this.usrAutToken()) {
      this.apiCall("fbase_token", { "auth_token": this.usrAutToken(), "usrId": this._usrId, "fbase_token": this.fbase_token })
        .subscribe(data => { });
    }

  }

  async translateSheet(ref) {


    this.translate.get(['cancel', 'chooseLang']).subscribe(async (res) => {

      let lang = "";
      let btns = []

      for (let k of this.lng_list) {
        btns.push({
          text: k.name,
          handler: () => {
            ref.doTranslate(k.lng, k.right)
          }
        })
      }

      btns.push(
        {
          text: res['cancel'],
          role: 'cancel',
          cssClass: 'btn-text-dark',
          handler: () => {
            //console.log('Cancel clicked');
          }
        }
      )

      const actionSheet = await this.actionSheetCtrl.create({
        header: res['chooseLang'],
        buttons: btns
      });
      actionSheet.present();

    })

  }

  translateMsg(msg, lang) {
    return this.apiCall("translate_text", { "auth_token": this.usrAutToken(), "usrId": this._usrId, "child_uid": this._currentChild, "msg": msg, "lang": lang })
  }

  randomStr() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  childFromList(child_uid) {
    return this._childList[child_uid]
  }

  findPhoneByIdx(index) {
    if (this.phone_list) {

      for (let k in this.phone_list) {
        for (let m of this.phone_list[k].list) {
          if (m.idx == index) {
            return m
          }
        }
      }
    }
    return null;
  }

  findArchivedAbsentByUid(uid) {
    for (var i in this.archived_absences_data) {
      if (this.archived_absences_data[i].uid == uid) {
        return this.archived_absences_data[i]
      }
    }

    return null;
  }

  findAbsentByUid(uid) {
    for (var i in this.absent_data) {
      if (this.absent_data[i].uid == uid) {
        return this.absent_data[i]
      }
    }

    return null;
  }

  findEvidenceByUid(uid) {
    for (var i in this.evidence_data) {
      if (this.evidence_data[i].uid == uid) {
        return this.evidence_data[i]
      }
    }

    return null;
  }

  findShortMessageByUid(uid) {
    for (var i in this.shortmessage_list) {
      if (this.shortmessage_list[i].uid == uid) {
        return this.shortmessage_list[i]
      }
    }
    return null;
  }

  findMessageByUid(uid) {
    for (var i in this.messages_data) {
      if (this.messages_data[i].uid == uid) {
        return this.messages_data[i]
      }
    }

    if (this.archived_messages_data) {
      for (var i in this.archived_messages_data) {
        if (this.archived_messages_data[i].uid == uid) {
          return this.archived_messages_data[i]
        }
      }
    }

    if (this.messages_activites && this.messages_activites[uid]) {
      return this.messages_activites[uid]
    }
    return null;
  }

  findNewsByUid(uid) {
    for (var i in this.news_streamData) {
      if (this.news_streamData[i].uid == uid) {
        return this.news_streamData[i]
      }
    }

    if (this.news_activites && this.news_activites[uid]) {
      return this.news_activites[uid]
    }
    return null;
  }

  findEventByUid(uid) {
    for (var i in this.events_data) {
      if (this.events_data[i].uid == uid) {
        return this.events_data[i]
      }
    }

    for (var i2 in this.events_past_data) {
      if (this.events_past_data[i2].uid == uid) {
        this.events_past_data[i2].past = true
        return this.events_past_data[i2]
      }
    }

    if (this.events_activites && this.events_activites[uid]) {
      return this.events_activites[uid]
    }
    return null;
  }


  updateEventEntriesByUid(uid, list) {

    for (var i in this.events_data) {
      if (this.events_data[i].uid == uid) {
        this.events_data[i].list_entries = JSON.parse(JSON.stringify(list))
      }
    }

  }

  getCurrentWeekday() {
    let weekdays: any = [this.weekdays_map["day1"], this.weekdays_map["day2"], this.weekdays_map["day3"], this.weekdays_map["day4"], this.weekdays_map["day5"], this.weekdays_map["day6"], this.weekdays_map["day7"]]
    let date = moment() //.add('days', 4)
    let day_now = date.day();

    if (day_now == 0) {
      date = date.add(1, 'day');
    }
    else if (day_now == 6) {
      date = date.add(2, 'days');
    }

    return [weekdays[date.day() - 1], date.isoWeek()];
  }


  checkForUpdates() {

    let activity_uid = ""

    if (this._activities && this._activities.length > 0) {
      activity_uid = this._activities[0].uid
    }

    let message_uid = ""

    if (this.messages_data && this.messages_data.length > 0) {
      message_uid = this.messages_data[0].cm_uid
    }

    this.apiCall("check_updates", {
      "auth_token": this.usrAutToken(),
      "usrId": this._usrId,
      "stamp": this.setup_stamp,
      "news_stamp": this.news_stamp,
      "child_uid": this._currentChild,
      "news_uid": this.news_streamData[0].uid,
      "news_skip": this.news_skip,
      "tracking_uid": (this.tracking_list2 && this.tracking_list2[0]) ? this.tracking_list2[0].uid : null,
      "tracking_skip": this.tracking_skip,
      "message_uid": message_uid,
      "activity_uid": activity_uid
    })
      .subscribe(async data => {
        //console.log("check_updates", data);

        this.setup_stamp = Math.floor(Date.now() / 1000)

        if ((<any>data).obj.news.list.length > 0) {
          let ll: any = (<any>data).obj.news.list
          this.news_streamData.unshift(...ll);
          this.news_more = (<any>data).obj.news.more;
          //this.streamData = this.kindyService.news_streamData
          //this.more = this.kindyService.news_more
        }

        if ((<any>data).obj.message.list.length > 0) {
          let ll: any = (<any>data).obj.message.list
          this.messages_data.unshift(...ll);
          this.prepareMessagesList()
        }

        if ((<any>data).obj.activities.list.length > 0) {
          let ll: any = (<any>data).obj.activities.list
          this._activities.unshift(...ll);
          await this.countUnreadActivities()
        }

        if ((<any>data).obj.events.list.length > 0) {
          this.events_data = (<any>data).obj.events.list;
          this.events_skip = (<any>data).obj.events.skip;
          this.events_more = (<any>data).obj.events.more;
        }

        if ((<any>data).obj.events_past.list.length > 0) {
          this.events_past_data = (<any>data).obj.events_past.list;
          this.events_past_skip = (<any>data).obj.events_past.skip;
          this.events_past_more = (<any>data).obj.events_past.more;
        }

        if ((<any>data).obj.tracking.list.length > 0) {
          this.tracking_skip = (<any>data).obj.tracking.skip;
          let ll: any = (<any>data).obj.tracking.list2
          this.tracking_list2.unshift(...ll);
          this.tracking_more = (<any>data).obj.tracking.more;
          this.convertTracking()
          //this.tracking_list = (<any>data).obj.tracking.list
        }

      },
        (err) => {
        }
      );

  }

  async presentToast(message, dur) {
    const toast = await this.toastController.create({
      message: message,
      position: 'middle',
      mode: 'ios',
      cssClass: "my-custom-class",
      duration: dur
    });
    toast.present();
  }


  async presentToastDefault(message) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      //cssClass: "my-custom-class",
      duration: 500
    });
    toast.present();
  }

  getSicknessNameByUid(uid) {
    for (let k in this.sickness_list) {
      for (let m of this.sickness_list[k].list) {
        if (m.uid == uid) {
          return m.name
        }
      }
    }
    null
  }

  eventChecklistChecked(checkentry) {
    if (checkentry.choosen) {
      for (let k = 0; k < checkentry.choosen.length; k++) {
        if (checkentry.choosen[k].user_id == this._usrId) {
          return 2
        }
      }

      if (!checkentry.count) {
        checkentry.count = 1
      }
      if (checkentry.choosen.length >= parseInt(checkentry.count)) {
        return 1
      }
    }

    return 0

  }

  async resetToStart() {

    if (this._modal_open) {
      this.modalController.dismiss()
    }

    this.navCtlr.navigateRoot("/start")
  }

  setKitalists(list) {
    if (list) {
      this._kitalists = list
    }
  }

  getKitalistMonth(list_uid, month) {
    if (this._kitalists) {
      for (let i = 0; i < this._kitalists.length; i++) {
        if (this._kitalists[i].uid == list_uid) {
          for (let k = 0; k < this._kitalists[i]["month"].length; k++) {
            if (this._kitalists[i]["month"][k]["mm-YYYY"] == month) {
              return this._kitalists[i]["month"][k]
            }
          }
        }
      }
    }
  }


  updateKitalist(list) {
    if (this._kitalists) {
      for (let i = 0; i < this._kitalists.length; i++) {
        console.log(this._kitalists[i], list.uid)
        if (this._kitalists[i].uid == list.uid) {
          console.log("ja updat")
          this._kitalists[i] = list
          return
        }
      }
    }
  }

  getKitalists() {
    if (this._kitalists) {
      return this._kitalists
    }
    return []
  }

  initSocket() {
    const userSocket = {
      auth_token: this.usrAutToken(),
      location_uid: this._locUid,
      userId: this._usrId
    }

    AppGlobal.rtcManager.init(null, userSocket, this.rtcCallback)
  }

  rtcCallback(data) {
    console.log("rtcCallback", data)
    AppGlobal.events.publish('rtc:event', { event: data.event, data: data.data });
  }


  timeToDecimal(timeString) {

    console.log(timeString)
    // Prüfe, ob die Eingabe dem Format "HH:MM" entspricht
    const regex = /^(\d{1,2}):(\d{2})$/;
    const match = timeString.match(regex);

    if (!match) {
      throw new Error("Ungültiges Zeitformat. Bitte verwenden Sie das Format 'HH:MM'.");
    }

    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);

    // Überprüfe, ob die Minuten gültig sind (0-59)
    if (minutes >= 60) {
      throw new Error("Ungültige Minutenangabe. Minuten müssen zwischen 0 und 59 liegen.");
    }

    // Berechne die Dezimalzahl
    const decimal = hours + (minutes / 60);

    // Runde auf zwei Dezimalstellen
    return Math.round(decimal * 100) / 100;
  }

  convertHoursToHHMM(hours) {
    // Extrahiere die ganzzahligen Stunden
    const hh = Math.floor(hours);

    // Berechne die Minuten
    const mm = Math.round((hours - hh) * 60);

    // Formatiere hh und mm als zweistellige Strings
    const formattedHH = hh.toString().padStart(2, '0');
    const formattedMM = mm.toString().padStart(2, '0');

    return { hh: formattedHH, mm: formattedMM };
  }

}
