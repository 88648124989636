import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { KindyService } from '../../services/kindy.service';

@Component({
  selector: 'app-abwesenheit-art',
  templateUrl: './abwesenheit-art.page.html',
  styleUrls: ['./abwesenheit-art.page.scss'],
})
export class AbwesenheitArtPage implements OnInit {

  isModal:Boolean; 
  absence_reason:string;
  
  constructor( private modalController : ModalController,
    private kindyService : KindyService,
    private navCtrl : NavController ) { }

  ngOnInit() { 
    if (!this.kindyService._currentChild) {
      this.kindyService.resetToStart()
      return
    } 

    if (this.kindyService.current_absent) {
      this.absence_reason = this.kindyService.current_absent.reason
    }
  }

  goBack() { 
    if (this.isModal) {
      this.modalController.dismiss()
    }
    else {
      this.navCtrl.back()
    }
  }

  chooseReasen(type) {
    this.kindyService.current_absent.reason = type
    this.kindyService.current_absent.sickness = ""
    this.goBack()
  }
}
